@import "../../../pagGlobalstyles/sass/global_variables";
@import "../../../pagGlobalstyles/sass/global_animations";
@import "../../../pagGlobalstyles/sass/fonts";

$tile__width: $size__xxl + $spacer__ml;
$tile__width--small: 40 * $grid__unit;
$tile__icon-width: $size__xxl;
$tile__icon-width--small: 32 * $grid__unit;
$tile__icon-size--small: $icon-size__l;
$tile__text--font-size: $font__size--normal;
$tile__text--line-height: $spacer__xl;
$tile__text--max-height: $size__xl;
$tile__text-fade: linear-gradient(to right, transparent 0, $color__primary--first 56px);
$tile__text-fade--arabic: linear-gradient(to left, transparent 0, $color__primary--first 56px);

.tile {
  @include font-pag-plain;
  #{--boxed}: true;

  .tile__link {
    text-decoration: none;
  }

  &.state-svg-not-loaded {
    display: none;
  }

  &.state-focused {
    .tile__icon-wrapper {
      @include focus-state("tile");
    }
  }

  &.mib3-dragged,
  &.state-pressed,
  &:active {
    .tile__text {
      color: $color__pressed;
    }

    .tile__icon {
      @include icon-style("pressed", "bevel", 1);
    }

    .tile__svg {
      @include icon-style("pressed", "bevel", 1, true);
    }

    & .animated-bg--growing-circle {
      // sass-lint:disable-line no-combinators
      @include animation(
        pressed-growing-circle $time__animation-duration--slow $animation-timing-function--bezier-easeout 1 both
      );
    }

    & .animated-bg--pressed {
      // sass-lint:disable-line no-combinators
      @include animation(
        pressed-background $time__animation-duration--slow $animation-timing-function--bezier-easeout 1 both
      );
      @include border("pressed");
    }
  }
}

.tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: $tile__width;
  will-change: transform;

  &__icon-wrapper {
    @include background("buttons-tiles", "normal");
    width: $tile__icon-width;
    height: $tile__icon-width;
    display: flex;
    position: relative;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;
    font-size: $size__xl;
  }

  &__icon-wrapper.state-active-tint,
  &__icon-wrapper.state-disabled-tint {
    &::after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      display: block;
    }
  }

  &__icon-wrapper.state-active-tint {
    &::after {
      background-color: $border__icon--color--active;
    }
  }

  &__icon-wrapper.state-disabled-tint {
    &::after {
      background-color: $color__icon--shadow--default;
    }
  }

  &__svg {
    @include icon-style("enabled", "bevel", 1, true);
    display: flex;
    justify-content: center;
    align-items: center;
    height: $tile__text--max-height;
    width: $tile__text--max-height;
  }

  &__icon {
    @include icon-style("enabled", "bevel", 1);
    position: relative;
    line-height: $size__xl;

    &.icon-setting {
      &::after {
        content: "\e967";
      }
    }

    // ::after element is only for tiles, where we have text-shadow. We have
    // ::before element for text-shadow, and ::after for colored icon. Everytime
    // when we add new tile, we also need to add ::after element for specific icon

    // to-do: We need to do this somehow differently I think, because we don't
    // need shadow everyhwere. @e.solutions?

    // sass-lint:disable class-name-format
    &.icon-notification {
      &::after {
        content: "\e9c1";
      }
    }

    &.icon-phone_receiver {
      &::after {
        content: "\e9c4";
      }
    }

    &.icon-car_front_j1 {
      &::after {
        content: "\e968";
      }
    }

    &.icon-note {
      &::after {
        content: "\e942";
      }
    }

    &.icon-north_arrow {
      &::after {
        content: "\e937";
      }
    }

    &.icon-update_center {
      &::after {
        content: "\e9cc";
      }
    }

    &.icon-predictive_maintenance {
      &::after {
        content: "\e9cb";
      }
    }

    &.icon-message_outline {
      &::after {
        content: "\e9ae";
      }
    }

    &.icon-concierge {
      &::after {
        content: "\e985";
      }
    }

    &.icon-connectivity {
      &::after {
        content: "\e976";
      }
    }

    &.icon-connect {
      &::after {
        content: "\ea90";
      }
    }

    &.icon-climate {
      &::after {
        content: "\ea1b";
      }
    }

    &.icon-charging_station {
      &::after {
        content: "\e914";
      }
    }

    &.icon-charging_active {
      &::after {
        content: "\eb43";
      }
    }

    &.icon-star {
      &::after {
        content: "\e92f";
      }
    }

    &.icon-connect_app {
      &:after {
        content: "\e975";
      }
    }

    &.icon-apple_carplay {
      &:after {
        content: "\e93b";
      }
    }

    &.icon-android_auto {
      &:after {
        content: "\ec55";
      }
    }

    &.icon-homelink {
      &::after {
        content: "\eb59";
      }
    }

    &.icon-timer {
      &::after {
        content: "\eb29";
      }
    }

    &.icon-weather {
      &::after {
        content: "\e93a";
      }
    }

    &.icon-channel {
      &::after {
        content: "\e920";
      }
    }

    &.icon-manual {
      &::after {
        content: "\eae6";
      }
    }

    &.icon-date_weekend {
      &::after {
        content: "\ea39";
      }
    }

    &.icon-date_today {
      &::after {
        content: "\ea38";
      }
    }

    &.icon-date_day {
      &::after {
        content: "\ea37";
      }
    }

    &.icon-news {
      &::after {
        content: "\eb51";
      }
    }

    &.icon-calendar {
      &::after {
        content: "\eb2a";
      }
    }

    &.icon-flight_info {
      &::after {
        content: "\eb53";
      }
    }

    &.icon-city_events {
      &::after {
        content: "\eb54";
      }
    }

    &.icon-codriver_cockpit {
      &::after {
        content: "\eb9b";
      }
    }

    &.icon-information_outline {
      &::after {
        content: "\e90d";
      }
    }

    &.icon-privacy {
      &::after {
        content: "\e958";
      }
    }

    &.icon-logo_feedly {
      &::after {
        content: "\ebb8";
      }
    }

    &.icon-organizer {
      &::after {
        content: "\eb2a";
      }
    }

    &.icon-etc_active {
      &::after {
        content: "\e96d";
      }
    }

    &.icon-freeway_toll {
      &::after {
        content: "\e9b6";
      }
    }

    &.icon-road_side_assistance {
      &::after {
        content: "\ea20";
      }
    }

    // sass-lint:enable class-name-format
  }

  &__text {
    max-width: $tile__width;
    margin-top: $spacer__s;
    font-size: $tile__text--font-size;
    line-height: $tile__text--line-height;
    text-align: center;
    color: $color__white--80;
  }

  &__text--overflow {
    display: flex;
    max-height: $tile__text--max-height;
    overflow: hidden;
    position: relative;
  }

  &__count {
    position: absolute;
    font-family: $font--plain-numeric;
    font-size: $tile__text--font-size;
  }

  &--small {
    width: $tile__width--small;

    .tile__icon-wrapper {
      width: $tile__icon-width--small;
      height: $tile__icon-width--small;
    }

    .tile__icon {
      max-width: $tile__icon-size--small;
      max-height: $tile__icon-size--small;

      &::before,
      &::after {
        font-size: $tile__icon-size--small;
      }
    }

    .tile__svg {
      width: $tile__icon-size--small;
      height: $tile__icon-size--small;
    }

    .tile__text {
      max-width: $tile__width--small;
    }
  }

  &.state-active {
    .tile__icon-wrapper {
      @include background("buttons-tiles", "active");
      border: 0;
    }

    .tile__text {
      color: $color__highlight;
    }

    .tile__icon {
      @include icon-style("active", "bevel");
    }

    .tile__svg {
      @include icon-style("active", "bevel", 1, true);
    }
  }

  &.state-disabled {
    cursor: not-allowed;

    .tile__icon-wrapper {
      @include background("buttons-tiles", "disabled");
    }

    .tile__icon {
      @include icon-style("disabled", "bevel");
    }

    .tile__svg {
      @include icon-style("disabled", "bevel", 1, true);
    }

    .tile__text {
      color: $color__disabled;
    }
  }
}

#{$selector-arabic-layout} {
  .tile__text--overflow {
    &::before {
      bottom: 0;
      left: 0;
      background-image: $tile__text-fade--arabic;
    }
  }
}
