@import '../../../../pagGlobalstyles/sass/global_variables';
@import '../../../../pagGlobalstyles/sass/fonts';

$scrollposition-indicator__margin--top: $spacer__s; // 12px
$scrollposition-indicator__height: calc(100% - (2 * #{$scrollposition-indicator__margin--top})); // 12px
$scrollposition-indicator__width: 1.25 * $grid__unit; // 5px
$scrollposition-indicator__margin--left-right: $spacer__m - $scrollposition-indicator__width; // 13px
$scrollposition-indicator__border-radius: (0.75 * $grid__unit) / 2; // 2px

$scrollposition-indicator-rail__width: 0.75 * $grid__unit; // 3px
$scrollposition-indicator-rail__background: $color__white--20;

$scrollposition-indicator-handle__background: $color__white--80;

.scrollposition-indicator__wrapper {
  display: flex;
  right: 0;
  justify-content: center;
  position: absolute;
  top: $scrollposition-indicator__margin--top;
  bottom: $scrollposition-indicator__margin--top;
  width: $scrollposition-indicator__width;
  min-width: $scrollposition-indicator__width;
  pointer-events: none;
  #{--boxed}: true;

  &--alfanumeric {
    top: 70px;
    right: $spacer__xs;
    bottom: 40px;

    &::before,
    &::after {
      display: inline-block;
      position: absolute;
      white-space: pre-line;
      color: $color__white--60;
      @include font-pag-semibold;
      font-size: 24px;
      line-height: 1.3;
      text-align: center;
    }

    &::before {
      content: "#\00000AA";
      top: -65px;
    }

    &::after {
      content: "Z";
      bottom: -40px;
    }
  }
}

.scrollposition-indicator {
  &__rail {
    position: relative;
    width: $scrollposition-indicator-rail__width;
    height: 100%;
    border-radius: $scrollposition-indicator__border-radius;
    background-color: $scrollposition-indicator-rail__background;
  }

  &__handle {
    position: absolute;
    width: $scrollposition-indicator__width;
    max-height: 100%;
    will-change: transform;
    transition: transform $time__animation-duration--veryfast ease-out;
    border-radius: $scrollposition-indicator__border-radius;
    background-color: $scrollposition-indicator-handle__background;
  }
}

.slideout-container {
  :host-context(.#{$class-name-latin-layout} &) {
    margin-right: 0;
    margin-left: $scrollposition-indicator__margin--left-right;
  }

  :host-context(.#{$class-name-arabic-layout} &) {
    margin-left: 0;
    margin-right: $scrollposition-indicator__margin--left-right;
  }
}

:host-context(.my-screen) {
  opacity: 0;
}

#{$selector-latin-layout} {
  right: 0;
}

#{$selector-arabic-layout} {
  left: 0;
}
