// sass-lint:disable no-mergeable-selectors
@import "./global_variables";

@font-face {
  font-family: $font--icons;
  src: url("../fonts/iconfont_DA_center/pag-iconfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.icon-apple_carplay {
  &:before {
    content: "\e93b";
  }
}

.icon-arrow_open_left {
  &:before {
    content: "\e93c";
  }
}

.icon-arrow_open_right {
  &:before {
    content: "\e93d";
  }
}

.icon-google_search {
  &:before {
    content: "\eb41";
  }
}

.icon-compass {
  &:before {
    content: "\ebf1";
  }
}

.icon-google_search_inactive {
  &:before {
    content: "\eb41";
  }
}

.icon-magnify_glass {
  &:before {
    content: "\e934";
  }
}

.icon-microphone {
  &:before {
    content: "\e93f";
  }
}

.icon-microphone_crossed {
  &:before {
    content: "\e940";
  }
}

.icon-note {
  &:before {
    content: "\e942";
  }
}

.icon-option_open {
  &:before {
    content: "\e943";
  }
}

.icon-person {
  &:before {
    content: "\e984";
  }
}

.icon-processing {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/general/icon-processing.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-voice_mail {
  &:before {
    content: "\e944";
  }
}

.icon-car_limousine {
  &:before {
    content: "\e945";
  }
}

.icon-car_limousine_niveau {
  &:before {
    content: "\e946";
  }
}

.icon-car_limousine_spoiler {
  &:before {
    content: "\e947";
  }
}

.icon-e_sound {
  &:before {
    content: "\e948";
  }
}

.icon-pasm {
  &:before {
    content: "\e949";
  }
}

.icon-3D_map {
  &:before {
    content: "\e9a4";
  }
}

.icon-scale_zoom_auto {
  &:before {
    content: "\e9a2";
  }
}

.icon-guidance_arrow {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/map/icon-guidance_arrow.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-scale_zoom_manually {
  &:before {
    content: "\e9a3";
  }
}

.icon-mute {
  &:before {
    content: "\e935";
  }
}

.icon-my_location {
  &:before {
    content: "\e936";
  }
}

.icon-north_arrow {
  &:before {
    content: "\e937";
  }
}

.icon-readout {
  &:before {
    content: "\e938";
  }
}

.icon-satellite {
  &:before {
    content: "\e97f";
  }
}

.icon-destination_flag_arrow {
  &:before {
    content: "\e981";
  }
}

.icon-stopover_flag_arrow {
  &:before {
    content: "\e982";
  }
}

.icon-selena {
  &:before {
    content: "\e939";
  }
}

.icon-speed_limit_60 {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/map/icon-speed_limit_60.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-speed_limit_rain {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/map/icon-speed_limit_rain.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-view {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/map/icon-view.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-weather {
  &:before {
    content: "\e93a";
  }
}

.icon-note_add_favorite {
  &:before {
    content: "\e923";
  }
}

.icon-note_circle {
  &:before {
    content: "\e921";
  }
}

.icon-pause {
  &:before {
    content: "\e924";
  }
}

.icon-play {
  &:before {
    content: "\e925";
  }
}

.icon-playlist {
  &:before {
    content: "\e926";
  }
}

.icon-repeat {
  &:before {
    content: "\e929";
  }
}

.icon-replay {
  &:before {
    content: "\e92a";
  }
}

.icon-shuffle {
  &:before {
    content: "\e92b";
  }
}

.icon-skip_backward {
  &:before {
    content: "\e92c";
  }
}

.icon-skip_forward {
  &:before {
    content: "\e92d";
  }
}

.icon-spotify {
  &:before {
    content: "\e92e";
  }
}

.icon-star {
  &:before {
    content: "\e92f";
  }
}

.icon-star_outline {
  &:before {
    content: "\e930";
  }
}

.icon-stop_scan {
  &:before {
    content: "\e931";
  }
}

.icon-tag {
  &:before {
    content: "\e932";
  }
}

.icon-bluetooth {
  &:before {
    content: "\e91e";
  }
}

.icon-channel {
  &:before {
    content: "\e920";
  }
}

.icon-cover {
  &:before {
    content: "\e922";
  }
}

.icon-live {
  &:before {
    content: "\e994";
  }
}

.icon-radio {
  &:before {
    content: "\e9c3";
  }
}

.icon-fm_linking_list {
  &:before {
    content: "\e927";
  }
}

.icon-usb {
  &:before {
    content: "\e933";
  }
}

.icon-art_culture {
  &:before {
    content: "\e913";
  }
}

.icon-charging_station {
  &:before {
    content: "\e914";
  }
}

.icon-destination_flag {
  &:before {
    content: "\e915";
  }
}

.icon-dls_top {
  &:before {
    content: "\e916";
  }
}

.icon-home {
  &:before {
    content: "\e96f";
  }
}

.icon-laptop {
  &:before {
    content: "\e918";
  }
}

.icon-microphone_hand {
  &:before {
    content: "\e919";
  }
}

.icon-nightlife {
  &:before {
    content: "\e91a";
  }
}

.icon-parking {
  &:before {
    content: "\e91b";
  }
}

.icon-sports {
  &:before {
    content: "\e91c";
  }
}

.icon-tour {
  &:before {
    content: "\e91d";
  }
}

.icon-delete_numbers {
  &:before {
    content: "\e909";
  }
}

.icon-charging_station_hpc {
  &::before {
    content: "\ebfd";
  }
}

.icon-delete_numbers_arabic {
  &:before {
    content: "\e90a";
  }
}

.icon-phone_hang_up {
  &:before {
    content: "\e90f";
  }
}

.icon-battery_0 {
  &:before {
    content: "\e901";
  }
}

.icon-battery_1 {
  &:before {
    content: "\e902";
  }
}

.icon-battery_2 {
  &:before {
    content: "\e903";
  }
}

.icon-battery_3 {
  &:before {
    content: "\e904";
  }
}

.icon-battery_4 {
  &:before {
    content: "\e905";
  }
}

.icon-battery_blank {
  &:before {
    content: "\e906";
  }
}

.icon-cancel {
  &:before {
    content: "\e907";
  }
}

.icon-cell_phone {
  &:before {
    content: "\e908";
  }
}

.icon-group {
  &:before {
    content: "\e90b";
  }
}

.icon-information {
  &:before {
    content: "\e90c";
  }
}

.icon-phone_add_call {
  &:before {
    content: "\e90e";
  }
}

.icon-phone_incoming_call {
  &:before {
    content: "\e910";
  }
}

.icon-phone_missed_call {
  &:before {
    content: "\e911";
  }
}

.icon-phone_outgoing_call {
  &:before {
    content: "\e912";
  }
}

.icon-phone_pause_call {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/phone/icon-phone_pause_call.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-phone_reactivate_call {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/phone/icon-phone_reactivate_call.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-3g {
  &:before {
    content: "\e979";
  }
}

.icon-car_front_j1 {
  &:before {
    content: "\e968";
  }
}

.icon-car_limousine_sound_off {
  &:before {
    content: "\e978";
  }
}

.icon-cell_phone_crossed {
  &:before {
    content: "\e973";
  }
}

.icon-circle_crossed {
  &:before {
    content: "\e974";
  }
}

.icon-connect_app {
  &:before {
    content: "\e975";
  }
}

.icon-connectivity {
  &:before {
    content: "\e976";
  }
}

.icon-connectivity_disabled {
  &:before {
    content: "\e977";
  }
}

.icon-edge {
  &:before {
    content: "\e96c";
  }
}

.icon-etc_active {
  &:before {
    content: "\e96d";
  }
}

.icon-etc_error {
  &:before {
    content: "\e96e";
  }
}

.icon-geo_localization {
  &:before {
    content: "\e972";
  }
}

.icon-ionisation {
  &:before {
    content: "\e970";
  }
}

.icon-lte {
  &:before {
    content: "\e971";
  }
}

.icon-network_0 {
  &:before {
    content: "\e96a";
  }
}

.icon-play_circle {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/media/apple_podcasts_listennow_neutral.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-network_0_arrow_forward {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/sidebars/icon-network_0_arrow_forward.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-network_1 {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/sidebars/icon-network_1.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-network_1_arrow_forward {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/sidebars/icon-network_1_arrow_forward.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-network_2 {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/sidebars/icon-network_2.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-network_2_arrow_forward {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/sidebars/icon-network_2_arrow_forward.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-network_3 {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/sidebars/icon-network_3.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-network_3_arrow_forward {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/sidebars/icon-network_3_arrow_forward.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-network_4 {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/sidebars/icon-network_4.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-network_4_arrow_forward {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/sidebars/icon-network_4_arrow_forward.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-network_5 {
  &:before {
    content: "\e96a";
  }
}

.icon-network_5_arrow_forward {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/sidebars/icon-network_5_arrow_forward.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-notification {
  &:before {
    content: "\e9c1";
  }
}

.icon-parking_assistant {
  &:before {
    content: "\e97b";
  }
}

.icon-parking_camera {
  &:before {
    content: "\e97c";
  }
}

.icon-privacy {
  &:before {
    content: "\e958";
  }
}

.icon-privacy_nad {
  &:before {
    content: "\e959";
  }
}

.icon-radio_no_signal {
  &:before {
    content: "\e928";
  }
}

.icon-recording {
  &:before {
    content: "\e95a";
  }
}

.icon-sim_card_privacy {
  &:before {
    content: "\e97d";
  }
}

.icon-wlan {
  &:before {
    content: "\e95b";
  }
}

.icon-at {
  &:before {
    content: "\e941";
  }
}

.icon-dot {
  &:before {
    content: "\e94a";
  }
}

.icon-enter {
  &:before {
    content: "\e94b";
  }
}

.icon-enter_arabic {
  &:before {
    content: "\e94c";
  }
}

.icon-globe {
  &:before {
    content: "\e94d";
  }
}

.icon-handwriting {
  &:before {
    content: "\e94e";
  }
}

.icon-keyboard {
  &:before {
    content: "\e94f";
  }
}

.icon-keyboard_hide {
  &:before {
    content: "\e950";
  }
}

.icon-shift {
  &:before {
    content: "\e951";
  }
}

.icon-space {
  &:before {
    content: "\e952";
  }
}

.icon-speller_characters {
  &:before {
    content: "\e953";
  }
}

.icon-speller_delete_numbers {
  &:before {
    content: "\e954";
  }
}

.icon-speller_delete_numbers_arabic {
  &:before {
    content: "\e955";
  }
}

.icon-speller_letters {
  &:before {
    content: "\e956";
  }
}

.icon-speller_numbers {
  &:before {
    content: "\e957";
  }
}

.icon-radio_button {
  &:before {
    content: "\ebbf";
  }
}

.icon-radio_button_checked {
  &:before {
    content: "\ebc0";
  }
}

.icon-box {
  &:before {
    content: "\ebbc";
  }
}

.icon-box_checked {
  &:before {
    content: "\ebbd";
  }
}

.icon-information_outline {
  &:before {
    content: "\e90d";
  }
}

.icon-delete_entry {
  &:before {
    content: "\e969";
  }
}

.icon-stopover_flag_add {
  &:before {
    content: "\e917";
  }
}

.icon-air_distribution_left {
  &:before {
    content: "\e995";
  }
}

.icon-circle_filled {
  &:before {
    content: "\e996";
  }
}

.icon-ac_eco {
  &:before {
    content: "\e966";
  }
}

.icon-ac {
  &:before {
    content: "\e965";
  }
}

.icon-air_off {
  &:before {
    content: "\e95e";
  }
}

.icon-circle_outline {
  &:before {
    content: "\e95d";
  }
}

.icon-setting {
  &:before {
    content: "\e967";
  }
}

.icon-map_layer {
  &:before {
    content: "\e980";
  }
}

.icon-air_distance_filled {
  &:before {
    content: "\e983";
  }
}

.icon-concierge {
  &:before {
    content: "\e985";
  }
}

.icon-air_distance_to_poi {
  &:before {
    content: "\e986";
  }
}

.icon-last_destination {
  &:before {
    content: "\e987";
  }
}

.icon-address_home {
  &:before {
    content: "\e988";
  }
}

.icon-address_work {
  &:before {
    content: "\e989";
  }
}

.icon-bin {
  &:before {
    content: "\e98a";
  }
}

.icon-edit {
  &:before {
    content: "\e98b";
  }
}

.icon-channel_no_signal {
  &:before {
    content: "\e98c";
  }
}

.icon-ipod {
  &:before {
    content: "\e98d";
  }
}

.icon-fm_dab_no_signal {
  &:before {
    content: "\e9a6";
  }
}

.icon-fm_dab {
  &:before {
    content: "\e9a5";
  }
}

.icon-fm_radio_no_signal {
  &:before {
    content: "\e9a6";
  }
}

.icon-fm_radio {
  &:before {
    content: "\e9a7";
  }
}

.icon-sirius_xm_no_signal {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/media/icon-sirius_xm_no_signal.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-sirius_xm {
  &:before {
    content: "\e9f6";
  }
}

.icon-epg {
  &:before {
    content: "\e98e";
  }
}

.icon-folder {
  &:before {
    content: "\e98f";
  }
}

.icon-fm_linking {
  &:before {
    content: "\e990";
  }
}

.icon-online_linking {
  &:before {
    content: "\e991";
  }
}

.icon-fast_backward {
  &:before {
    content: "\e992";
  }
}

.icon-fast_forward {
  &:before {
    content: "\e993";
  }
}

.icon-car_limousine_auto_recirculation {
  &:before {
    content: "\e997";
  }
}

.icon-repeat_title {
  &:before {
    content: "\e998";
  }
}

.icon-podcast {
  &:before {
    content: "\e999";
  }
}

.icon-warning_filled {
  &:before {
    content: "\e99a";
  }
}

.icon-aca_lks {
  &:before {
    content: "\e99b";
  }
}

.icon-back_out_assistant {
  &:before {
    content: "\e99c";
  }
}

.icon-cross_over_assistant {
  &:before {
    content: "\e99d";
  }
}

.icon-lane_change_assist {
  &:before {
    content: "\e99e";
  }
}

.icon-exit_warning {
  &:before {
    content: "\e99f";
  }
}

.icon-traffic_assist_j1 {
  &:before {
    content: "\e9a0";
  }
}

.icon-emergency_assist_j1 {
  &:before {
    content: "\e9a1";
  }
}

.icon-arrow_open_up {
  &:before {
    content: "\e9a9";
  }
}

.icon-arrow_open_down {
  &:before {
    content: "\e9aa";
  }
}

.icon-route {
  &:before {
    content: "\e9ac";
  }
}

.icon-seat_right {
  &:before {
    content: "\ea03";
  }
}

.icon-seat_left {
  &:before {
    content: "\ea04";
  }
}

.icon-seat_ventilation_right {
  &:before {
    content: "\ea06";
  }
}

.icon-seat_ventilation_left {
  &:before {
    content: "\ea05";
  }
}

.icon-phone_receiver_green {
  &:before {
    content: "\e9ad";
  }
}

.icon-message_outline {
  &:before {
    content: "\e9ae";
  }
}

.icon-lane_departure_warning_system_j1 {
  &:before {
    content: "\e9af";
  }
}

.icon-emergency_assist {
  &:before {
    content: "\e9b0";
  }
}

.icon-air_center_j1 {
  &:before {
    content: "\ea21";
  }
}

.icon-seat_heating_right {
  &:before {
    content: "\e9b1";
  }
}

.icon-seat_heating_left {
  &:before {
    content: "\e9b2";
  }
}

.icon-road {
  &:before {
    content: "\e9b3";
  }
}

.icon-freeway {
  &:before {
    content: "\e9b4";
  }
}

.icon-hov_avoid_not {
  &:before {
    content: "\e9b5";
  }
}

.icon-freeway_toll {
  &:before {
    content: "\e9b6";
  }
}

.icon-avoid_ferry_car_train_not {
  &:before {
    content: "\e9b7";
  }
}

.icon-tunnel {
  &:before {
    content: "\e9b8";
  }
}

.icon-altitude {
  &:before {
    content: "\e9b9";
  }
}

.icon-message_draft {
  &:before {
    content: "\e9c9";
  }
}

.icon-message_inbox {
  &:before {
    content: "\e9c7";
  }
}

.icon-message_outbox {
  &:before {
    content: "\e9c8";
  }
}

.icon-message_sent {
  &:before {
    content: "\e9c6";
  }
}

.icon-message_template {
  &:before {
    content: "\e9ca";
  }
}

.icon-ass_basic {
  &:before {
    content: "\e9c5";
  }
}

.icon-options_more {
  &:before {
    content: "\e9c2";
  }
}

.icon-ass_j1 {
  &:before {
    content: "\e9ba";
  }
}

.icon-pdc_mute {
  &:before {
    content: "\e9bb";
  }
}

.icon-on_off {
  &:before {
    content: "\e9f7";
  }
}

.icon-4G {
  &:before {
    content: "\e96b";
  }
}

.icon-predictive_maintenance {
  &:before {
    content: "\e9cb";
  }
}

.icon-speller_coordinates {
  &:before {
    content: "\e9cd";
  }
}

.icon-warning_sign {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/assist/icon-warning_sign.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-poi {
  &:before {
    content: "\e9bc";
  }
}

.icon-stopover_flag {
  &:before {
    content: "\e9bd";
  }
}

.icon-night_vision {
  &:before {
    content: "\e9be";
  }
}

.icon-update_center {
  &:before {
    content: "\e9cc";
  }
}

.icon-air_style_right {
  &:before {
    content: "\e9c0";
  }
}

.icon-phone_receiver {
  &:before {
    content: "\e9c4";
  }
}

.icon-electricity {
  &:before {
    content: "\e9ce";
  }
}

.icon-contacts {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/home/icon-contacts.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-wind {
  &:before {
    content: "\e9cf";
  }
}

.icon-sun {
  &:before {
    content: "\e9d0";
  }
}

.icon-rain {
  &:before {
    content: "\e9d1";
  }
}

.icon-trailer_view {
  &:before {
    content: "\e9d2";
  }
}

.icon-ipa {
  &:before {
    content: "\e9d3";
  }
}

.icon-rtv_panorama_view {
  &:before {
    content: "\e9d4";
  }
}

.icon-maneuver_assist_j1 {
  &:before {
    content: "\e9d5";
  }
}

.icon-cleaning_camera {
  &:before {
    content: "\e9d6";
  }
}

.icon-camera_arrow {
  &:before {
    content: "\ea2f";
  }
}

.icon-message_write {
  &:before {
    content: "\e9d7";
  }
}

.icon-destination_flag_stop {
  &:before {
    content: "\e9f3";
  }
}

.icon-stopover_flag_delete {
  &:before {
    content: "\e9d8";
  }
}

.icon-ambient_light {
  &:before {
    content: "\e9d9";
  }
}

.icon-four_squares {
  &:before {
    content: "\e9da";
  }
}

.icon-cell_phone_link {
  &:before {
    content: "\e9db";
  }
}

.icon-porsche_connect_app {
  &:before {
    content: "\e9dc";
  }
}

.icon-maneuver_assist_j1_off {
  &:before {
    content: "\e9f8";
  }
}

.icon-ipa_scanner {
  &:before {
    content: "\e9f4";
  }
}

.icon-trip_continuous {
  &:before {
    content: "\e9dd";
  }
}

.icon-trip_personal {
  &:before {
    content: "\e9de";
  }
}

.icon-trip_since_refuelling {
  &:before {
    content: "\e9df";
  }
}

.icon-trip_since {
  &:before {
    content: "\e9e0";
  }
}

.icon-PoweredByGracenote_Logo {
  &:before {
    content: "\e9e1";
  }
}

.icon-radionet_Logo {
  &:before {
    content: "\e9e3";
  }
}

.icon-circle {
  &:before {
    content: "\e9e4";
  }
}

.icon-camera {
  &:before {
    content: "\e9e5";
  }
}

.icon-indicator_warning {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/general/icon-indicator_warning.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-arrow_up {
  &:before {
    content: "\e900";
  }
}

.icon-update {
  &:before {
    content: "\e9e8";
  }
}

.icon-comma_fullstop_hiragana {
  &:before {
    content: "\e9f0";
  }
}

.icon-hiragana_function_key {
  &:before {
    content: "\e9f9";
  }
}

.icon-poi_filled {
  &:before {
    content: "\e97a";
  }
}

.icon-apple_icloud {
  &:before {
    content: "\e9e6";
  }
}

.icon-split_calls {
  &:before {
    content: "\ea34";
  }
}

.icon-more {
  &:before {
    content: "\e9e7";
  }
}

.icon-ac_fan_only {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/ac/icon-ac_fan_only.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-addressbook {
  &:before {
    content: "\e9e9";
  }
}

.icon-all {
  &:before {
    content: "\e9ea";
  }
}

.icon-all_outline {
  &:before {
    content: "\e9eb";
  }
}

.icon-asterisk {
  &:before {
    content: "\e9ec";
  }
}

.icon-asterisk_asia {
  &:before {
    content: "\e9ed";
  }
}

.icon-bubble_info {
  &:before {
    content: "\e9ee";
  }
}

.icon-communication {
  &:before {
    content: "\e9ef";
  }
}

.icon-comma_fullstop_taiwan {
  &:before {
    content: "\e9f1";
  }
}

.icon-comma_point_hiragana {
  &:before {
    content: "\e9f2";
  }
}

.icon-logo_sirius_xm_long {
  &:before {
    content: "\e9f5";
  }
}

.icon-logo_sirius_xm_short {
  &:before {
    content: "\e9f6";
  }
}

.icon-maximize {
  &:before {
    content: "\ea30";
  }
}

.icon-minimize {
  &:before {
    content: "\ea31";
  }
}

.icon-parking_cross_backward_left {
  &:before {
    content: "\e95f";
  }
}

.icon-parking_cross_backward_right {
  &:before {
    content: "\e960";
  }
}

.icon-parking_cross_forward_left {
  &:before {
    content: "\e961";
  }
}

.icon-parking_cross_forward_right {
  &:before {
    content: "\e962";
  }
}

.icon-parking_garage {
  &:before {
    content: "\e963";
  }
}

.icon-parking_parallel_left {
  &:before {
    content: "\e964";
  }
}

.icon-parking_parallel_right {
  &:before {
    content: "\ea2e";
  }
}

.icon-ipa_search_right {
  &:before {
    content: "\e9fa";
  }
}

.icon-ipa_search_left {
  &:before {
    content: "\e9fb";
  }
}

.icon-genre {
  &:before {
    content: "\e9fc";
  }
}

.icon-family {
  &:before {
    content: "\e9fd";
  }
}

.icon-knowledge {
  &:before {
    content: "\e9fe";
  }
}

.icon-festival {
  &:before {
    content: "\e9ff";
  }
}

.icon-list {
  &:before {
    content: "\ea00";
  }
}

.icon-bookmark {
  &:before {
    content: "\ea01";
  }
}

.icon-themes {
  &:before {
    content: "\ea02";
  }
}

.icon-date_weekend {
  &:before {
    content: "\ea39";
  }
}

.icon-date_today {
  &:before {
    content: "\ea38";
  }
}

.icon-date_day {
  &:before {
    content: "\ea37";
  }
}

.icon-check {
  &:before {
    content: "\ea07";
  }
}

.icon-plus {
  &:before {
    content: "\ea08";
  }
}

.icon-air_distribution_diffuse {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/ac/icon-air_distribution_diffuse.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-air_distribution_focus {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/ac/icon-air_distribution_focus.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-pid_upcoming_events_right_curve {
  &:before {
    content: "\ea09";
  }
}

.icon-pid_upcoming_events_branch_off_left {
  &:before {
    content: "\ea0a";
  }
}

.icon-pid_upcoming_events_branch_off_right {
  &:before {
    content: "\ea0b";
  }
}

.icon-pid_upcoming_events_highway_exit_left {
  &:before {
    content: "\ea0c";
  }
}

.icon-pid_upcoming_events_highway_exit_right {
  &:before {
    content: "\ea0d";
  }
}

.icon-pid_upcoming_events_junction {
  &:before {
    content: "\ea0e";
  }
}

.icon-pid_upcoming_events_left_curve {
  &:before {
    content: "\ea0f";
  }
}

.icon-pid_upcoming_events_priority_to_left_a {
  &:before {
    content: "\ea10";
  }
}

.icon-pid_upcoming_events_priority_to_left_b {
  &:before {
    content: "\ea11";
  }
}

.icon-pid_upcoming_events_priority_to_right_a {
  &:before {
    content: "\ea12";
  }
}

.icon-pid_upcoming_events_priority_to_right_b {
  &:before {
    content: "\ea13";
  }
}

.icon-other_road {
  &:before {
    content: "\ea14";
  }
}

.icon-bypass {
  &:before {
    content: "\ea15";
  }
}

.icon-ass_disabled_j1 {
  &:before {
    content: "\ea16";
  }
}

.icon-recuperation {
  &:before {
    content: "\ea17";
  }
}

.icon-clock_time {
  &:before {
    content: "\ea18";
  }
}

.icon-update_save {
  &:before {
    content: "\ea19";
  }
}

.icon-arrow_down {
  &:before {
    content: "\ea1a";
  }
}

.icon-climate {
  &:before {
    content: "\ea1b";
  }
}

.icon-ipa_leave_left {
  &:before {
    content: "\ea1c";
  }
}

.icon-ipa_leave_right {
  &:before {
    content: "\ea1d";
  }
}

.icon-logo_radio_net_long {
  &:before {
    content: "\ea1e";
  }
}

.icon-drag_vertical {
  &:before {
    content: "\ea1f";
  }
}

.icon-road_side_assistance {
  &:before {
    content: "\ea20";
  }
}

.icon-air_distribution_left_down {
  &:before {
    content: "\ea2d";
  }
}

.icon-air_distribution_left_mid {
  &:before {
    content: "\ea2c";
  }
}

.icon-air_distribution_left_up {
  &:before {
    content: "\ea2b";
  }
}

.icon-air_distribution_right_down {
  &:before {
    content: "\ea2a";
  }
}

.icon-air_distribution_right_mid {
  &:before {
    content: "\ea29";
  }
}

.icon-air_distribution_right_up {
  &:before {
    content: "\ea28";
  }
}

.icon-air_style_left_3 {
  &:before {
    content: "\ea27";
  }
}

.icon-air_style_left_2 {
  &:before {
    content: "\ea26";
  }
}

.icon-air_style_left_1 {
  &:before {
    content: "\ea25";
  }
}

.icon-air_style_right_3 {
  &:before {
    content: "\ea24";
  }
}

.icon-air_style_right_2 {
  &:before {
    content: "\ea23";
  }
}

.icon-air_style_right_1 {
  &:before {
    content: "\ea22";
  }
}

.icon-destination_flag_left {
  &:before {
    content: "\eb72";
  }
}

.icon-destination_flag_offroad {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/map/icon-destination_flag_offroad.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-destination_flag_right {
  &:before {
    content: "\eb73";
  }
}

.icon-stopover_flag_left {
  &:before {
    content: "\eb83";
  }
}

.icon-stopover_flag_offroad {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/map/icon-stopover_flag_offroad.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-stopover_flag_right {
  &:before {
    content: "\eb82";
  }
}

.icon-ac_max {
  &:before {
    content: "\ea32";
  }
}

.icon-conference_call {
  &:before {
    content: "\ea33";
  }
}

.icon-scale_zoom_stopover {
  &:before {
    content: "\ea35";
  }
}

.icon-scale_zoom_destination {
  &:before {
    content: "\ea36";
  }
}

.icon-air_distribution_right {
  &:before {
    content: "\e9ab";
  }
}

.icon-saved {
  &:before {
    content: "\ea3a";
  }
}

.icon-pilopa {
  &:before {
    content: "\ea3c";
  }
}

.icon-logo_bose {
  &:before {
    content: "\ea3d";
  }
}

.icon-logo_burmester {
  &:before {
    content: "\ea3f";
  }
}

.icon-notification_extended {
  &:before {
    content: "\ea40";
  }
}

.icon-lg_dr_e0 {
  &:before {
    content: "\ea41";
  }
}

.icon-lg_dr_c0 {
  &:before {
    content: "\ea42";
  }
}

.icon-lg_dr_a0 {
  &:before {
    content: "\ea43";
  }
}

.icon-lg_dr_92 {
  &:before {
    content: "\ea44";
  }
}

.icon-lg_dr_72 {
  &:before {
    content: "\ea45";
  }
}

.icon-lg_dr_60 {
  &:before {
    content: "\ea46";
  }
}

.icon-lg_dr_40 {
  &:before {
    content: "\ea47";
  }
}

.icon-lg_dr_20 {
  &:before {
    content: "\ea48";
  }
}

.icon-lg_dr_00 {
  &:before {
    content: "\ea49";
  }
}

.icon-lg_2s_dr_e0_mitte {
  &:before {
    content: "\ea4a";
  }
}

.icon-lg_2s_dr_e0_links_lang {
  &:before {
    content: "\ea4b";
  }
}

.icon-lg_2s_dr_e0_links_kurz {
  &:before {
    content: "\ea4c";
  }
}

.icon-lg_2s_dr_c0_mitte {
  &:before {
    content: "\ea4d";
  }
}

.icon-lg_2s_dr_c0_mitte_kurz {
  &:before {
    content: "\ea4e";
  }
}

.icon-lg_2s_dr_c0_links_lang {
  &:before {
    content: "\ea4f";
  }
}

.icon-lg_2s_dr_c0_links_kurz {
  &:before {
    content: "\ea50";
  }
}

.icon-lg_2s_dr_a0_mitte {
  &:before {
    content: "\ea51";
  }
}

.icon-lg_2s_dr_a0_mitte_kurz {
  &:before {
    content: "\ea52";
  }
}

.icon-lg_2s_dr_a0_links_lang {
  &:before {
    content: "\ea53";
  }
}

.icon-lg_2s_dr_a0_links_kurz {
  &:before {
    content: "\ea54";
  }
}

.icon-lg_2s_dr_92_mitte {
  &:before {
    content: "\ea55";
  }
}

.icon-lg_2s_dr_92_links {
  &:before {
    content: "\ea56";
  }
}

.icon-lg_2s_dr_72_rechts {
  &:before {
    content: "\ea57";
  }
}

.icon-lg_2s_dr_72_mitte {
  &:before {
    content: "\ea58";
  }
}

.icon-lg_2s_dr_60_rechts_lang {
  &:before {
    content: "\ea59";
  }
}

.icon-lg_2s_dr_60_rechts_kurz {
  &:before {
    content: "\ea5a";
  }
}

.icon-lg_2s_dr_60_mitte {
  &:before {
    content: "\ea5b";
  }
}

.icon-lg_2s_dr_60_mitte_kurz {
  &:before {
    content: "\ea5c";
  }
}

.icon-lg_2s_dr_40_rechts_lang {
  &:before {
    content: "\ea5d";
  }
}

.icon-lg_2s_dr_40_rechts_kurz {
  &:before {
    content: "\ea5e";
  }
}

.icon-lg_2s_dr_40_mitte {
  &:before {
    content: "\ea5f";
  }
}

.icon-lg_2s_dr_40_mitte_kurz {
  &:before {
    content: "\ea60";
  }
}

.icon-lg_2s_dr_20_rechts_lang {
  &:before {
    content: "\ea61";
  }
}

.icon-lg_2s_dr_20_rechts_kurz {
  &:before {
    content: "\ea62";
  }
}

.icon-lg_2s_dr_20_mitte {
  &:before {
    content: "\ea63";
  }
}

.icon-lg_2s_dr_00_rechts {
  &:before {
    content: "\ea64";
  }
}

.icon-lg_2s_dr_00_links {
  &:before {
    content: "\ea65";
  }
}

.icon-lg_3s_dr_e0_mitte {
  &:before {
    content: "\ea66";
  }
}

.icon-lg_3s_dr_e0_mitte_lang {
  &:before {
    content: "\ea67";
  }
}

.icon-lg_3s_dr_e0_links {
  &:before {
    content: "\ea68";
  }
}

.icon-lg_3s_dr_e0_links_lang {
  &:before {
    content: "\ea69";
  }
}

.icon-lg_3s_dr_c0_mitte_lang {
  &:before {
    content: "\ea6a";
  }
}

.icon-lg_3s_dr_c0_mitte_kurz {
  &:before {
    content: "\ea6b";
  }
}

.icon-lg_3s_dr_c0_links {
  &:before {
    content: "\ea6c";
  }
}

.icon-lg_3s_dr_c0_links_lang {
  &:before {
    content: "\ea6d";
  }
}

.icon-lg_3s_dr_c0_links_kurz {
  &:before {
    content: "\ea6e";
  }
}

.icon-lg_3s_dr_a0_mitte {
  &:before {
    content: "\ea6f";
  }
}

.icon-lg_3s_dr_a0_mitte_lang {
  &:before {
    content: "\ea70";
  }
}

.icon-lg_3s_dr_a0_mitte_kurz {
  &:before {
    content: "\ea71";
  }
}

.icon-lg_3s_dr_a0_links {
  &:before {
    content: "\ea72";
  }
}

.icon-lg_3s_dr_a0_links_lang {
  &:before {
    content: "\ea73";
  }
}

.icon-lg_3s_dr_a0_links_kurz {
  &:before {
    content: "\ea74";
  }
}

.icon-lg_3s_dr_92_mitte {
  &:before {
    content: "\ea75";
  }
}

.icon-lg_3s_dr_92_mitte_kurz {
  &:before {
    content: "\ea76";
  }
}

.icon-lg_3s_dr_92_links {
  &:before {
    content: "\ea77";
  }
}

.icon-lg_3s_dr_92_links_kurz {
  &:before {
    content: "\ea78";
  }
}

.icon-lg_3s_dr_72_rechts {
  &:before {
    content: "\ea79";
  }
}

.icon-lg_3s_dr_72_rechts_kurz {
  &:before {
    content: "\ea7a";
  }
}

.icon-lg_3s_dr_72_mitte {
  &:before {
    content: "\ea7b";
  }
}

.icon-lg_3s_dr_72_mitte_kurz {
  &:before {
    content: "\ea7c";
  }
}

.icon-lg_3s_dr_60_rechts {
  &:before {
    content: "\ea7d";
  }
}

.icon-lg_3s_dr_60_rechts_lang {
  &:before {
    content: "\ea7e";
  }
}

.icon-lg_3s_dr_60_rechts_kurz {
  &:before {
    content: "\ea7f";
  }
}

.icon-lg_3s_dr_60_mitte {
  &:before {
    content: "\ea80";
  }
}

.icon-lg_3s_dr_60_mitte_lang {
  &:before {
    content: "\ea81";
  }
}

.icon-lg_3s_dr_60_mitte_kurz {
  &:before {
    content: "\ea82";
  }
}

.icon-lg_3s_dr_40_rechts {
  &:before {
    content: "\ea83";
  }
}

.icon-lg_3s_dr_40_rechts_lang {
  &:before {
    content: "\ea84";
  }
}

.icon-lg_3s_dr_40_rechts_kurz {
  &:before {
    content: "\ea85";
  }
}

.icon-lg_3s_dr_40_mitte_lang {
  &:before {
    content: "\ea86";
  }
}

.icon-lg_3s_dr_40_mitte_kurz {
  &:before {
    content: "\ea87";
  }
}

.icon-lg_3s_dr_20_rechts {
  &:before {
    content: "\ea88";
  }
}

.icon-lg_3s_dr_20_rechts_lang {
  &:before {
    content: "\ea89";
  }
}

.icon-lg_3s_dr_20_mitte {
  &:before {
    content: "\ea8a";
  }
}

.icon-lg_3s_dr_20_mitte_lang {
  &:before {
    content: "\ea8b";
  }
}

.icon-lg_3s_dr_00_rechts {
  &:before {
    content: "\ea8c";
  }
}

.icon-lg_3s_dr_00_mitte {
  &:before {
    content: "\ea8d";
  }
}

.icon-lg_3s_dr_00_links {
  &:before {
    content: "\ea8e";
  }
}

.icon-lane_widget_points {
  &:before {
    content: "\ea8f";
  }
}

.icon-buslane {
  &:before {
    content: "\ea93";
  }
}

.icon-04_base_layer_RoundAbout {
  &:before {
    content: "\ea94";
  }
}

.icon-04_00_p_TurnArrow_UTurnC0 {
  &:before {
    content: "\ea95";
  }
}

.icon-04_00_p_TurnArrow_UTurn40 {
  &:before {
    content: "\ea96";
  }
}

.icon-04_00_p_TurnArrow_StandardTurnE0 {
  &:before {
    content: "\ea97";
  }
}

.icon-04_00_p_TurnArrow_StandardTurnC0 {
  &:before {
    content: "\ea98";
  }
}

.icon-04_00_p_TurnArrow_StandardTurnA0 {
  &:before {
    content: "\ea99";
  }
}

.icon-04_00_p_TurnArrow_StandardTurn60 {
  &:before {
    content: "\ea9a";
  }
}

.icon-04_00_p_TurnArrow_StandardTurn40 {
  &:before {
    content: "\ea9b";
  }
}

.icon-04_00_p_TurnArrow_StandardTurn20 {
  &:before {
    content: "\ea9c";
  }
}

.icon-04_00_p_TurnArrow_StandardTurn00 {
  &:before {
    content: "\ea9d";
  }
}

.icon-04_00_p_TurnArrow_SquareTrsRightF0 {
  &:before {
    content: "\ea9e";
  }
}

.icon-04_00_p_TurnArrow_SquareTrsRightE0 {
  &:before {
    content: "\ea9f";
  }
}

.icon-04_00_p_TurnArrow_SquareTrsRightB0_D0 {
  &:before {
    content: "\eaa0";
  }
}

.icon-04_00_p_TurnArrow_SquareTrsRight90_A0 {
  &:before {
    content: "\eaa1";
  }
}

.icon-04_00_p_TurnArrow_SquareTrsRight80 {
  &:before {
    content: "\eaa2";
  }
}

.icon-04_00_p_TurnArrow_SquareTrsRight60_70 {
  &:before {
    content: "\eaa3";
  }
}

.icon-04_00_p_TurnArrow_SquareTrsRight30_50 {
  &:before {
    content: "\eaa4";
  }
}

.icon-04_00_p_TurnArrow_SquareTrsRight20 {
  &:before {
    content: "\eaa5";
  }
}

.icon-04_00_p_TurnArrow_SquareTrsRight10 {
  &:before {
    content: "\eaa6";
  }
}

.icon-04_00_p_TurnArrow_SquareTrsRight00 {
  &:before {
    content: "\eaa7";
  }
}

.icon-04_00_p_TurnArrow_SquareTrsLeftF0 {
  &:before {
    content: "\eaa8";
  }
}

.icon-04_00_p_TurnArrow_SquareTrsLeftE0 {
  &:before {
    content: "\eaa9";
  }
}

.icon-04_00_p_TurnArrow_SquareTrsLeftB0_D0 {
  &:before {
    content: "\eaaa";
  }
}

.icon-04_00_p_TurnArrow_SquareTrsLeft90_A0 {
  &:before {
    content: "\eaab";
  }
}

.icon-04_00_p_TurnArrow_SquareTrsLeft80 {
  &:before {
    content: "\eaac";
  }
}

.icon-04_00_p_TurnArrow_SquareTrsLeft60_70 {
  &:before {
    content: "\eaad";
  }
}

.icon-04_00_p_TurnArrow_SquareTrsLeft30_50 {
  &:before {
    content: "\eaae";
  }
}

.icon-04_00_p_TurnArrow_SquareTrsLeft20 {
  &:before {
    content: "\eaaf";
  }
}

.icon-04_00_p_TurnArrow_SquareTrsLeft10 {
  &:before {
    content: "\eab0";
  }
}

.icon-04_00_p_TurnArrow_SquareTrsLeft00 {
  &:before {
    content: "\eab1";
  }
}

.icon-04_00_p_TurnArrow_ServiceRoadRight00 {
  &:before {
    content: "\eab2";
  }
}

.icon-04_00_p_TurnArrow_ServiceRoadLeft00 {
  &:before {
    content: "\eab3";
  }
}

.icon-04_00_p_TurnArrow_RoundAboutTrsRightD0_F0 {
  &:before {
    content: "\eab4";
  }
}

.icon-04_00_p_TurnArrow_RoundAboutTrsRightC0 {
  &:before {
    content: "\eab5";
  }
}

.icon-04_00_p_TurnArrow_RoundAboutTrsRight90_B0 {
  &:before {
    content: "\eab6";
  }
}

.icon-04_00_p_TurnArrow_RoundAboutTrsRight80 {
  &:before {
    content: "\eab7";
  }
}

.icon-04_00_p_TurnArrow_RoundAboutTrsRight50_70 {
  &:before {
    content: "\eab8";
  }
}

.icon-04_00_p_TurnArrow_RoundAboutTrsRight40 {
  &:before {
    content: "\eab9";
  }
}

.icon-04_00_p_TurnArrow_RoundAboutTrsRight10_30 {
  &:before {
    content: "\eaba";
  }
}

.icon-04_00_p_TurnArrow_RoundAboutTrsRight00 {
  &:before {
    content: "\eabb";
  }
}

.icon-04_00_p_TurnArrow_RoundAboutTrsLeftD0_F0 {
  &:before {
    content: "\eabc";
  }
}

.icon-04_00_p_TurnArrow_RoundAboutTrsLeftC0 {
  &:before {
    content: "\eabd";
  }
}

.icon-04_00_p_TurnArrow_RoundAboutTrsLeft90_B0 {
  &:before {
    content: "\eabe";
  }
}

.icon-04_00_p_TurnArrow_RoundAboutTrsLeft80 {
  &:before {
    content: "\eabf";
  }
}

.icon-04_00_p_TurnArrow_RoundAboutTrsLeft50_70 {
  &:before {
    content: "\eac0";
  }
}

.icon-04_00_p_TurnArrow_RoundAboutTrsLeft40 {
  &:before {
    content: "\eac1";
  }
}

.icon-04_00_p_TurnArrow_RoundAboutTrsLeft10_30 {
  &:before {
    content: "\eac2";
  }
}

.icon-04_00_p_TurnArrow_RoundAboutTrsLeft00 {
  &:before {
    content: "\eac3";
  }
}

.icon-04_00_p_TurnArrow_OffRoadD0_F0 {
  &:before {
    content: "\eac4";
  }
}

.icon-04_00_p_TurnArrow_OffRoadC0 {
  &:before {
    content: "\eac5";
  }
}

.icon-04_00_p_TurnArrow_OffRoad90_B0 {
  &:before {
    content: "\eac6";
  }
}

.icon-04_00_p_TurnArrow_OffRoad80 {
  &:before {
    content: "\eac7";
  }
}

.icon-04_00_p_TurnArrow_OffRoad50_70 {
  &:before {
    content: "\eac8";
  }
}

.icon-04_00_p_TurnArrow_OffRoad40 {
  &:before {
    content: "\eac9";
  }
}

.icon-04_00_p_TurnArrow_OffRoad10_30 {
  &:before {
    content: "\eaca";
  }
}

.icon-04_00_p_TurnArrow_OffRoad00 {
  &:before {
    content: "\eacb";
  }
}

.icon-04_00_p_TurnArrow_MichiganUTurnC0 {
  &:before {
    content: "\eacc";
  }
}

.icon-04_00_p_TurnArrow_MichiganUTurn40 {
  &:before {
    content: "\eacd";
  }
}

.icon-04_00_p_TurnArrow_ForkC0 {
  &:before {
    content: "\eace";
  }
}

.icon-04_00_p_TurnArrow_Fork40 {
  &:before {
    content: "\eacf";
  }
}

.icon-04_00_p_TurnArrow_FollowStreetFF {
  &:before {
    content: "\ead0";
  }
}

.icon-04_00_p_TurnArrow_FollowStreetC0 {
  &:before {
    content: "\ead1";
  }
}

.icon-04_00_p_TurnArrow_FollowStreet40 {
  &:before {
    content: "\ead2";
  }
}

.icon-04_00_p_TurnArrow_ExitRightC0_E0 {
  &:before {
    content: "\ead3";
  }
}

.icon-04_00_p_TurnArrow_ExitRight00 {
  &:before {
    content: "\ead4";
  }
}

.icon-04_00_p_TurnArrow_ExitLeftt20_40 {
  &:before {
    content: "\ead5";
  }
}

.icon-04_00_p_TurnArrow_ExitLeft00 {
  &:before {
    content: "\ead6";
  }
}

.icon-04_00_p_TurnArrow_ChangeLaneC0 {
  &:before {
    content: "\ead7";
  }
}

.icon-04_00_p_TurnArrow_ChangeLane40 {
  &:before {
    content: "\ead8";
  }
}

.icon-arrow_open_full_right {
  &:before {
    content: "\ead9";
  }
}

.icon-add_bluetooth {
  &:before {
    content: "\eb33";
  }
}

.icon-source_bluetooth {
  &:before {
    content: "\eb2b";
  }
}

.icon-source_usb {
  &:before {
    content: "\eb31";
  }
}

.icon-logo_7eleven {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/connect/news/icon-logo_7eleven.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-logo_afp {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/connect/news/icon-logo_afp.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-logo_ansa {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/connect/news/icon-logo_ansa.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-logo_autonavi {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/connect/news/icon-logo_autonavi.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-logo_db_bahn {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/connect/news/icon-logo_db_bahn.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-logo_deutsche_welle {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/connect/news/icon-logo_deutsche_welle.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-logo_eventseeker {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/connect/news/icon-logo_eventseeker.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-logo_flight_stats {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/connect/news/icon-logo_flight_stats.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-logo_HeFeng {
  &:before {
    content: "\eade";
  }
}

.icon-logo_inrix {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/connect/news/icon-logo_inrix.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-logo_merian {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/connect/news/icon-logo_merian.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-logo_meteoGroup {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/connect/news/icon-logo_meteoGroup.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-logo_navitime {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/connect/news/icon-logo_navitime.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-logo_npr {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/connect/news/icon-logo_npr.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-logo_OpisNavx {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/connect/news/icon-logo_OpisNavx.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-logo_pag {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/connect/news/icon-logo_pag.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-logo_parkopedia {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/connect/news/icon-logo_parkopedia.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-logo_rss {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/connect/news/icon-logo_rss.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-logo_SID {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/connect/news/icon-logo_SID.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-logo_Tagesschau {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/connect/news/icon-logo_Tagesschau.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-logo_twitter {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/connect/news/icon-logo_twitter.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-logo_wefind {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/connect/news/icon-logo_wefind.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-logo_Xinhua {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/connect/news/icon-logo_Xinhua.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-logo_yellowmap {
  &:before {
    content: "\eb71";
  }
}

.icon-arrow_open_full_left {
  &:before {
    content: "\eada";
  }
}

.icon-charge_timer {
  &:before {
    content: "\eadb";
  }
}

.icon-pid_upcoming_events_traffic_jam_end {
  &:before {
    content: "\eadc";
  }
}

.icon-hd_voice {
  &:before {
    content: "\eadd";
  }
}

.icon-logo_auro_3d {
  &:before {
    content: "\eadf";
  }
}

.icon-logo_powered_by_google {
  &:before {
    content: "\eae0";
  }
}

.icon-b_call_premium {
  &:before {
    content: "\eae1";
  }
}

.icon-online_radio {
  &:before {
    content: "\eae2";
  }
}

.icon-video_image_fullscreen {
  &:before {
    content: "\eae3";
  }
}

.icon-video_image_fullscreen_close {
  &:before {
    content: "\eae4";
  }
}

.icon-easy_entry {
  &:before {
    content: "\eae5";
  }
}

.icon-manual {
  &:before {
    content: "\eae6";
  }
}

.icon-tour_flag {
  &:before {
    content: "\eb37";
  }
}

.icon-abs_failure {
  &:before {
    content: "\eae7";
  }
}

.icon-abs_na {
  &:before {
    content: "\eae8";
  }
}

.icon-steering_wheel {
  &:before {
    content: "\eae9";
  }
}

.icon-acc {
  &:before {
    content: "\eaea";
  }
}

.icon-airbag_warning {
  &:before {
    content: "\eaeb";
  }
}

.icon-voltage {
  &:before {
    content: "\eaec";
  }
}

.icon-brake_worn_linings {
  &:before {
    content: "\eaed";
  }
}

.icon-brake_wear_na {
  &:before {
    content: "\eaee";
  }
}

.icon-brake_failure {
  &:before {
    content: "\eaef";
  }
}

.icon-brake_fluid_na {
  &:before {
    content: "\eaf0";
  }
}

.icon-sos {
  &:before {
    content: "\eaf1";
  }
}

.icon-parking_brake {
  &:before {
    content: "\eaf2";
  }
}

.icon-car_limousine_warning {
  &:before {
    content: "\eaf3";
  }
}

.icon-esp_psm_off {
  &:before {
    content: "\eaf4";
  }
}

.icon-4WD {
  &:before {
    content: "\eaf5";
  }
}

.icon-car_limousine_outside_temp {
  &:before {
    content: "\eaf6";
  }
}

.icon-tempomat {
  &:before {
    content: "\eaf7";
  }
}

.icon-seat_belt {
  &:before {
    content: "\eaf8";
  }
}

.icon-hal_1_2 {
  &:before {
    content: "\eaf9";
  }
}

.icon-car_limousine_warning_hybrid {
  &:before {
    content: "\eafa";
  }
}

.icon-pid {
  &:before {
    content: "\eafb";
  }
}

.icon-childlock {
  &:before {
    content: "\eafc";
  }
}

.icon-engine_coolant_level {
  &:before {
    content: "\eafd";
  }
}

.icon-beam_levelling_control {
  &:before {
    content: "\eafe";
  }
}

.icon-lgi_traktion {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/map/icon-lgi_traktion.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-lgi_sicht {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/map/icon-lgi_sicht.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-lgi_unfall {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/map/icon-lgi_unfall.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-light_failure {
  &:before {
    content: "\eaff";
  }
}

.icon-traffic_sign_speed_limit_eu {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/map/icon-traffic_sign_speed_limit_eu.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-traffic_sign_speed_limit_nar {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/map/icon-traffic_sign_speed_limit_nar.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-traffic_sign_speed_limit_cn {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/map/icon-traffic_sign_speed_limit_cn.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-speed_limit_warning_vae {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/map/icon-speed_limit_warning_vae.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-traffic_sign_no_entry_eu {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/map/icon-traffic_sign_no_entry_eu.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-smart_key {
  &:before {
    content: "\eb00";
  }
}

.icon-traffic_sign_animal_eu {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/map/icon-traffic_sign_animal_eu.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-traffic_sign_pedestrians_eu {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/map/icon-traffic_sign_pedestrians_eu.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-pasm_warning {
  &:before {
    content: "\eb01";
  }
}

.icon-pdcc_j1 {
  &:before {
    content: "\eb02";
  }
}

.icon-pos_lever {
  &:before {
    content: "\eb03";
  }
}

.icon-traffic_sign_traffic_lights_free_right_turn_eu {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/map/icon-traffic_sign_traffic_lights_free_right_turn_eu.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-prioritysign_arrow_na {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/map/icon-prioritysign_arrow_na.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-traffic_sign_traffic_lights_no_turn_nar {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/map/icon-traffic_sign_traffic_lights_no_turn_nar.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-traffic_sign_give_way_eu {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/map/icon-traffic_sign_give_way_eu.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-prioritysign_roundabout {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/map/icon-prioritysign_roundabout.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-traffic_sign_stop_eu {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/map/icon-traffic_sign_stop_eu.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-traffic_sign_traffic_lights_eu {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/map/icon-traffic_sign_traffic_lights_eu.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-cross_lock {
  &:before {
    content: "\eb04";
  }
}

.icon-warning_tire_pressure {
  &:before {
    content: "\eb05";
  }
}

.icon-engine {
  &:before {
    content: "\eb06";
  }
}

.icon-e_range {
  &:before {
    content: "\eb07";
  }
}

.icon-lock_locked {
  &:before {
    content: "\eb08";
  }
}

.icon-key {
  &:before {
    content: "\eb09";
  }
}

.icon-service {
  &:before {
    content: "\eb0a";
  }
}

.icon-voltage_high {
  &:before {
    content: "\eb0b";
  }
}

.icon-voltage_none {
  &:before {
    content: "\eb0c";
  }
}

.icon-coolant_temperature {
  &:before {
    content: "\eb0e";
  }
}

.icon-traffic_sign_recognition_j1 {
  &:before {
    content: "\eb0f";
  }
}

.icon-front_window_wiping_water {
  &:before {
    content: "\eb10";
  }
}

.icon-lim {
  &:before {
    content: "\eb11";
  }
}

.icon-car_limousine_ready_cn {
  &:before {
    content: "\eb12";
  }
}

.icon-electric_driving_mileage_of_hybrid_vehicles_mode_cn {
  &:before {
    content: "\eb13";
  }
}

.icon-limited_performance_mode_power_cn {
  &:before {
    content: "\eb14";
  }
}

.icon-limited_performance_mode_turtle_cn {
  &:before {
    content: "\eb15";
  }
}

.icon-ev_charging_connector_lock_cn {
  &:before {
    content: "\eb16";
  }
}

.icon-car_limousine_system_failure_text_cn {
  &:before {
    content: "\eb18";
  }
}

.icon-electric_motor_failure_cn {
  &:before {
    content: "\eb19";
  }
}

.icon-sds_pickup_list_entry_1 {
  &:before {
    content: "\eb1a";
  }
}

.icon-sds_pickup_list_entry_2 {
  &:before {
    content: "\eb1b";
  }
}

.icon-sds_pickup_list_entry_3 {
  &:before {
    content: "\eb1c";
  }
}

.icon-sds_pickup_list_entry_4 {
  &:before {
    content: "\eb1d";
  }
}

.icon-propulsion_battery_temperature {
  &:before {
    content: "\eb1e";
  }
}

.icon-parking_light {
  &:before {
    content: "\eb1f";
  }
}

.icon-plug {
  &:before {
    content: "\eb20";
  }
}

.icon-sidelights {
  &:before {
    content: "\eb21";
  }
}

.icon-door_lock_limousine {
  &:before {
    content: "\eb22";
  }
}

.icon-aca_hands_off {
  &:before {
    content: "\eb23";
  }
}

.icon-fod {
  &:before {
    content: "\eb24";
  }
}

.icon-update_error {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/ota/icon-update_error.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-lgi_panne {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/map/icon-lgi_panne.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-filler_cap_electric_warning_left {
  &:before {
    content: "\eb17";
  }
}

.icon-filler_cap_electric_warning_right {
  &:before {
    content: "\eb0d";
  }
}

.icon-gforce_acceleration {
  &:before {
    content: "\eb26";
  }
}

.icon-gforce_delay {
  &:before {
    content: "\eb27";
  }
}

.icon-gforce_lateral_acceleration {
  &:before {
    content: "\eb25";
  }
}

.icon-filter {
  &:before {
    content: "\eb28";
  }
}

.icon-timer {
  &:before {
    content: "\eb29";
  }
}

.icon-soc_destination_0 {
  &:before {
    content: "\eb3a";
  }
}

.icon-soc_destination_1 {
  &:before {
    content: "\eb35";
  }
}

.icon-soc_destination_2 {
  &:before {
    content: "\eb3e";
  }
}

.icon-soc_destination_3 {
  &:before {
    content: "\eb2e";
  }
}

.icon-soc_destination_4 {
  &:before {
    content: "\eb2d";
  }
}

.icon-soc_destination_5 {
  &:before {
    content: "\eb3b";
  }
}

.icon-soc_destination_blank {
  &:before {
    content: "\eb3f";
  }
}

.icon-organizer {
  &:before {
    content: "\eb2a";
  }
}

.icon-logo_taycan {
  &:before {
    content: "\ea3b";
  }
}

.icon-charging_inductive {
  &:before {
    content: "\eb3d";
  }
}

.icon-homelink_send {
  &:before {
    content: "\eb36";
  }
}

.icon-plim_offset {
  &:before {
    content: "\eb39";
  }
}

.icon-plim_three_digits {
  &:before {
    content: "\eb38";
  }
}

.icon-plim_two_digits {
  &:before {
    content: "\eb3c";
  }
}

.icon-poi_listing {
  &:before {
    content: "\eb2c";
  }
}

.icon-brake_key_failure {
  &:before {
    content: "\eb34";
  }
}

.icon-charging_station_warning {
  &:before {
    content: "\eb32";
  }
}

.icon-charging_station_warning_outline {
  &:before {
    content: "\eb2f";
  }
}

.icon-logo_cityseeker {
  &:before {
    content: "\eb30";
  }
}

.icon-eco_plus {
  &:before {
    content: "\eb6b";
  }
}

.icon-update_security {
  &:before {
    content: "\eb40";
  }
}

.icon-recuperation_level_adaptive {
  &:before {
    content: "\eb42";
  }
}

.icon-assist_on_off {
  &:before {
    content: "\ebc6";
  }
}

.icon-codriver_cockpit {
  &:before {
    content: "\eb9b";
  }
}

.icon-charging_active {
  &:before {
    content: "\eb43";
  }
}

.icon-filler_cap_ac_left {
  &:before {
    content: "\eb44";
  }
}

.icon-filler_cap_ac_right {
  &:before {
    content: "\eb45";
  }
}

.icon-filler_cap_dc_left {
  &:before {
    content: "\eb46";
  }
}

.icon-filler_cap_dc_right {
  &:before {
    content: "\eb47";
  }
}

.icon-filler_cap_dcac_left {
  &:before {
    content: "\eb48";
  }
}

.icon-filler_cap_dcac_right {
  &:before {
    content: "\eb49";
  }
}

.icon-plug_left_ac_cn {
  &:before {
    content: "\eb4a";
  }
}

.icon-plug_right_dc_cn {
  &:before {
    content: "\eb4b";
  }
}

.icon-plug_short_left {
  &:before {
    content: "\eb4c";
  }
}

.icon-plug_short_right {
  &:before {
    content: "\eb4d";
  }
}

.icon-charge_premium {
  &:before {
    content: "\eb4e";
  }
}

.icon-parking_plus {
  &:before {
    content: "\eb4f";
  }
}

.icon-air_footwell_left_cold {
  &:before {
    content: "\e91f";
  }
}

.icon-air_footwell_left_warm {
  &:before {
    content: "\eba2";
  }
}

.icon-air_footwell_left {
  &:before {
    content: "\eb52";
  }
}

.icon-air_footwell_right_cold {
  &:before {
    content: "\eba3";
  }
}

.icon-air_footwell_right_warm {
  &:before {
    content: "\eba4";
  }
}

.icon-air_footwell_right {
  &:before {
    content: "\eb55";
  }
}

.icon-speak {
  &:before {
    content: "\eb56";
  }
}

.icon-message {
  &:before {
    content: "\eb57";
  }
}

.icon-sim_card {
  &:before {
    content: "\eb58";
  }
}

.icon-homelink {
  &:before {
    content: "\eb59";
  }
}

.icon-logo_qi {
  &:before {
    content: "\eb5a";
  }
}

.icon-duration {
  &:before {
    content: "\eb5b";
  }
}

.icon-money {
  &:before {
    content: "\eb7a";
  }
}

.icon-seat_massage_strength_right {
  &:before {
    content: "\eb5c";
  }
}

.icon-seat_massage_strength_left {
  &:before {
    content: "\eb5d";
  }
}

.icon-seat_massage_strength_5_right {
  &:before {
    content: "\eb5e";
  }
}

.icon-seat_massage_strength_5_left {
  &:before {
    content: "\eb5f";
  }
}

.icon-seat_massage_strength_4_right {
  &:before {
    content: "\eb60";
  }
}

.icon-seat_massage_strength_4_left {
  &:before {
    content: "\eb61";
  }
}

.icon-seat_massage_strength_3_right {
  &:before {
    content: "\eb62";
  }
}

.icon-seat_massage_strength_3_left {
  &:before {
    content: "\eb63";
  }
}

.icon-seat_massage_strength_2_right {
  &:before {
    content: "\eb64";
  }
}

.icon-seat_massage_strength_2_left {
  &:before {
    content: "\eb65";
  }
}

.icon-seat_massage_strength_1_right {
  &:before {
    content: "\eb66";
  }
}

.icon-seat_massage_strength_1_left {
  &:before {
    content: "\eb67";
  }
}

.icon-seat_massage_right {
  &:before {
    content: "\eb68";
  }
}

.icon-seat_massage_left {
  &:before {
    content: "\eb69";
  }
}

.icon-e_ac {
  &:before {
    content: "\eb6a";
  }
}

.icon-seat_comfort_codriver_left {
  &:before {
    content: "\eb6d";
  }
}

.icon-seat_comfort_left {
  &:before {
    content: "\eb6c";
  }
}

.icon-seat_config_codriver_left {
  &:before {
    content: "\eb6e";
  }
}

.icon-seat_easy_entry_left {
  &:before {
    content: "\eb6f";
  }
}

.icon-cityseeker_skyline {
  &:before {
    content: "\eb70";
  }
}

.icon-person_login {
  &:before {
    content: "\eb74";
  }
}

.icon-car_limousine_hood {
  &:before {
    content: "\eb75";
  }
}

.icon-car_sport_trunk_close_arrow {
  &:before {
    content: "\eb76";
  }
}

.icon-car_sport_trunk_open_arrow {
  &:before {
    content: "\eb77";
  }
}

.icon-plug_ccs_type2 {
  &:before {
    content: "\eb78";
  }
}

.icon-plug_chademo {
  &:before {
    content: "\eb7b";
  }
}

.icon-plug_iec_type1 {
  &:before {
    content: "\eb7c";
  }
}

.icon-plug_iec_type2 {
  &:before {
    content: "\eb7d";
  }
}

.icon-plug_ccs_type_1 {
  &:before {
    content: "\eb7e";
  }
}

.icon-plug_gbt_20234_2_cn {
  &:before {
    content: "\eb7f";
  }
}

.icon-plug_gbt_20234_3_cn {
  &:before {
    content: "\eb80";
  }
}

.icon-stopover_flag_left_with_lable {
  &:before {
    content: "\eb84";
  }
}

.icon-stopover_flag_right_label {
  &:before {
    content: "\eb81";
  }
}

.icon-04_base_layer_Square {
  &:before {
    content: "\eb85";
  }
}

.icon-home_sync {
  &:before {
    content: "\eb86";
  }
}

.icon-logo_yelp {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/navi/icon-logo_yelp.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-phone_switch_call {
  &:before {
    content: "\eb87";
  }
}

.icon-rating_cityseeker_0_5 {
  &:before {
    content: "\eb88";
  }
}

.icon-rating_cityseeker_0 {
  &:before {
    content: "\eb89";
  }
}

.icon-rating_cityseeker_1_5 {
  &:before {
    content: "\eb8a";
  }
}

.icon-rating_cityseeker_1 {
  &:before {
    content: "\eb8b";
  }
}

.icon-rating_cityseeker_2_5 {
  &:before {
    content: "\eb8c";
  }
}

.icon-rating_cityseeker_2 {
  &:before {
    content: "\eb8d";
  }
}

.icon-rating_cityseeker_3_5 {
  &:before {
    content: "\eb8e";
  }
}

.icon-rating_cityseeker_3 {
  &:before {
    content: "\eb8f";
  }
}

.icon-rating_cityseeker_3star_0_5 {
  &:before {
    content: "\eb90";
  }
}

.icon-rating_cityseeker_3star_0 {
  &:before {
    content: "\eb91";
  }
}

.icon-rating_cityseeker_3star_1_5 {
  &:before {
    content: "\eb92";
  }
}

.icon-rating_cityseeker_3star_1 {
  &:before {
    content: "\eb93";
  }
}

.icon-rating_cityseeker_3star_2_5 {
  &:before {
    content: "\eb94";
  }
}

.icon-rating_cityseeker_3star_2 {
  &:before {
    content: "\eb95";
  }
}

.icon-rating_cityseeker_3star_3 {
  &:before {
    content: "\eb96";
  }
}

.icon-rating_cityseeker_4_5 {
  &:before {
    content: "\eb97";
  }
}

.icon-rating_cityseeker_4 {
  &:before {
    content: "\eb98";
  }
}

.icon-rating_cityseeker_5 {
  &:before {
    content: "\eb99";
  }
}

.icon-rating_yelp_0 {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/navi/icon-rating_yelp_0.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-rating_yelp_1_5 {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/navi/icon-rating_yelp_1_5.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-rating_yelp_1 {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/navi/icon-rating_yelp_1.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-rating_yelp_2_5 {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/navi/icon-rating_yelp_2_5.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-rating_yelp_2 {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/navi/icon-rating_yelp_2.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-rating_yelp_3_5 {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/navi/icon-rating_yelp_3_5.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-rating_yelp_3 {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/navi/icon-rating_yelp_3.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-rating_yelp_4_5 {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/navi/icon-rating_yelp_4_5.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-rating_yelp_4 {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/navi/icon-rating_yelp_4.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-rating_yelp_5 {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/navi/icon-rating_yelp_5.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-sxm_possession {
  &:before {
    content: "\eb9a";
  }
}

.icon-parking_brake_na {
  &:before {
    content: "\eb79";
  }
}

.icon-lgi_aquaplaning {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/general/icon-lgi_aquaplaning.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-traffic_antenna {
  &:before {
    content: "\eb9c";
  }
}

.icon-traffic_sign_danger_eu {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/map/icon-traffic_sign_danger_eu.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-logo_hd_radio {
  &:before {
    content: "\eb9d";
  }
}

.icon-globe_na {
  &:before {
    content: "\eb50";
  }
}

.icon-news {
  &:before {
    content: "\eb51";
  }
}

.icon-flight_info {
  &:before {
    content: "\eb53";
  }
}

.icon-city_events {
  &:before {
    content: "\eb54";
  }
}

.icon-privacy_data {
  &:before {
    content: "\eb9e";
  }
}

.icon-privacy_data_tracking {
  &:before {
    content: "\eb9f";
  }
}

.icon-privacy_data_gps {
  &:before {
    content: "\eba0";
  }
}

.icon-data_tracking {
  &:before {
    content: "\eba1";
  }
}

.icon-wheel {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/assist/icon-wheel.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-arrow_n_button {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/assist/icon-arrow_n_button.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-globe_small_na {
  &:before {
    content: "\eba5";
  }
}

.icon-globe_small {
  &:before {
    content: "\eba6";
  }
}

.icon-sxm_notification_outline {
  &:before {
    content: "\ebcc";
  }
}

.icon-sxm_for_you {
  &:before {
    content: "\eba7";
  }
}

.icon-sxm_notification {
  &:before {
    content: "\eba8";
  }
}

.icon-sxm_on_demand {
  &:before {
    content: "\eba9";
  }
}

.icon-apple_add_to_library {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/media/icon-apple_add_to_library.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-apple_dislike {
  &:before {
    content: "\ebd5";
  }
}

.icon-apple_for_you {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/media/icon-apple_for_you.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-apple_love {
  &:before {
    content: "\ebd4";
  }
}

.icon-apple_radio {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/media/icon-apple_radio.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-apple_podcasts_library {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/media/apple_podcasts_library_neutral.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}
.icon-skip_30s_backward {
  &:before {
    content: "\ebc8";
  }
}

.icon-skip_30s_forward {
  &:before {
    content: "\ebc9";
  }
}

.icon-logo_apple_music {
  &:before {
    content: "\ebb1";
  }
}

.icon-apple_remove_from_playlist {
  &:before {
    content: "\ebb0";
  }
}

.icon-apple_play_next {
  &:before {
    content: "\ebaf";
  }
}

.icon-apple_play_later {
  &:before {
    content: "\ebae";
  }
}

.icon-apple_lyrics {
  &:before {
    content: "\ebad";
  }
}

.icon-apple_library {
  &:before {
    content: "\ebac";
  }
}

.icon-apple_create_station {
  &:before {
    content: "\ebab";
  }
}

.icon-apple_add_to_playlist {
  &:before {
    content: "\ebaa";
  }
}

.icon-qr_code_na {
  &:before {
    content: "\ebb2";
  }
}

.icon-bubble_recommendations {
  &:before {
    content: "\ebb3";
  }
}

.icon-charging_target_state {
  &:before {
    content: "\ebb4";
  }
}

.icon-charging_profile {
  &:before {
    content: "\ebb5";
  }
}

.icon-swipe {
  &:before {
    content: "\ea3e";
  }
}

.icon-phone_ring_tone {
  &:before {
    content: "\e9e2";
  }
}

.icon-parking_forward {
  &:before {
    content: "\ebb6";
  }
}

.icon-filler_cap_mechanic_warning_right {
  &:before {
    content: "\ebb9";
  }
}

.icon-filler_cap_mechanic_warning_left {
  &:before {
    content: "\ebba";
  }
}

.icon-plug_small {
  &:before {
    content: "\ebb7";
  }
}

.icon-logo_feedly {
  &:before {
    content: "\ebb8";
  }
}

.icon-e_range_nav {
  &:before {
    content: "\ebbb";
  }
}

.icon-box_checked_empty {
  &:before {
    content: "\ebbe";
  }
}

.icon-apple_explicit {
  &:before {
    content: "\ebc5";
  }
}

.icon-rear_window_defrost {
  &:before {
    content: "\ebc1";
  }
}

.icon-front_window_defrost {
  &:before {
    content: "\ebc2";
  }
}

.icon-skip_15s_backward {
  &:before {
    content: "\ebc3";
  }
}

.icon-skip_15s_forward {
  &:before {
    content: "\ebc4";
  }
}

.icon-traffic_sign_speed_limit_advisory_kmh_nar_can {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/map/icon-traffic_sign_speed_limit_advisory_kmh_nar_can.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-traffic_sign_speed_limit_kmh_nar_can {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/map/icon-traffic_sign_speed_limit_kmh_nar_can.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-e_range_cn {
  &:before {
    content: "\ebc7";
  }
}

.icon-sxm_fast_backward {
  &:before {
    content: "\ebca";
  }
}

.icon-sxm_fast_forward {
  &:before {
    content: "\ebcb";
  }
}

.icon-traffic_sign_speed_limit_eu_kmh {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/map/icon-traffic_sign_speed_limit_eu_kmh.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-traffic_sign_speed_limit_eu_mph {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/map/icon-traffic_sign_speed_limit_eu_mph.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-traffic_sign_speed_limit_generic_nar_can {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/map/icon-traffic_sign_speed_limit_generic_nar_can.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-carpool {
  &:before {
    content: "\ebcd";
  }
}

.icon-logo_apple_music_color {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/media/logo_apple_music_color.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-logo_apple_podcasts {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/media/icon-logo_apple_podcasts.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-logo_apple_podcasts_color {
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../pagCenter/assets/icon-master/media/icon-logo_apple_podcasts_color.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-connect {
  &:before {
    content: "\ea90";
  }
}

.icon-export {
  &:before {
    content: "\ea91";
  }
}

.icon-news_channel {
  &:before {
    content: "\ea92";
  }
}

.icon-eye {
  &:before {
    content: "\ebce";
  }
}

.icon-eye_off {
  &:before {
    content: "\ebcf";
  }
}

.icon-subject {
  &:before {
    content: "\ebd0";
  }
}

.icon-homelink_send_1 {
  &:before {
    content: "\ebd1";
  }
}

.icon-homelink_send_2 {
  &:before {
    content: "\ebd2";
  }
}

.icon-homelink_send_3 {
  &:before {
    content: "\ebd3";
  }
}

.icon-trip {
  &:before {
    content: "\ebd6";
  }
}

.icon-traffic_info_outline_ece {
  &:before {
    content: "\ebd7";
  }
}

.icon-traffic_info_outline_nar {
  &:before {
    content: "\ebd8";
  }
}

.icon-air {
  &:before {
    content: "\ebd9";
  }
}

.icon-base_mode_menu {
  &:before {
    content: "\ebda";
  }
}
