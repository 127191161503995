@import "../../../../../../../pagGlobalstyles/sass/global_variables";

$headline--line-height: 20 * $grid__unit; // 80px
$headline__width: 179 * $grid__unit;
$headline--max-width: $headline__width - $icon-size__m - $spacer__s - $spacer__m;
$headline--margin-top: 3.25 * $grid__unit; // 13px
$headline--margin: $spacer__s;

$headline__height: 31 * $grid__unit; // 124px

$address-item__max-height: 16 * $grid__unit; // 64px
$address-item__width: 85.25 * $grid__unit; // 341px
$headline__padding: 2.5 * $grid__unit; // 10px

$poi-list-item-end__padding--left: 11.75 * $grid__unit; // 47px
$poi-list-item-end__width: 28.25 * $grid__unit; // 113px

$poi-stack-list__width: 184.5 * $grid__unit; // 738px

$poi-list-item-end__divider-position: -3 * $grid__unit; // -12px

$icon-details__padding-right: 11.75 * $grid__unit; // 47px

$icon-details-divider__top: 4.5 * $grid__unit; // 18px
$icon-details-divider__right: 4.75 * $grid__unit; // 19px

$distance__margin-top: 1.75 * $grid__unit; // 7px

.poi-stack-list-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: $poi-stack-list__width;
  #{--boxed}: false;
}

.headline {
  display: flex;
  justify-content: flex-start;
  font-size: $font__size--m;
  height: $headline__height;
  max-width: $headline--max-width;
  margin-top: $headline--margin-top;
  margin-bottom: $headline--margin;
  color: $color__highlight;
  overflow: hidden;
  white-space: nowrap;
}

.poi-list {
  height: 100%;
}

.poi-list__item {
  display: flex;
  justify-content: flex-start;

  &--center-end {
    display: flex;
    align-items: center;
    height: $font__size--m;
    font-size: $font__size--m;
  }

  &--end {
    display: flex;
    width: $poi-list-item-end__width;
  }
}

.icon-details {
  @include icon-divider;
  position: relative;
  box-sizing: content-box;

  &::before {
    top: $icon-details-divider__top;
    background: transparent;
  }
}

.address-item {
  max-height: $address-item__max-height;
  width: $address-item__width;
  font-size: $font__size--m;
}

.distance {
  margin-top: $distance__margin-top;
  white-space: nowrap;
  font-size: $font__size--normal;
}

.pois-available {
  white-space: nowrap;
}

// #{$selector-latin-layout} {
.address-item {
  @include text-fading(left);
}

.headline {
  @include text-fading(left);
  padding-left: $headline__padding;

  &__amount {
    padding-right: $spacer__s;
  }
}

.icon-details {
  padding-left: $poi-list-item-end__padding--left;

  &::before {
    left: $icon-details-divider__right;
  }

  &::after {
    left: $poi-list-item-end__divider-position;
  }
}
// }

#{$selector-arabic-layout} {
  .address-item {
    @include text-fading(right);
  }

  .headline {
    @include text-fading(right);
    direction: rtl;
    margin-right: $spacer__s;

    &__amount {
      padding-left: $spacer__s;
    }
  }

  .icon-details {
    @include icon-divider("right");
    padding-right: $icon-details__padding-right;

    &::before {
      top: $icon-details-divider__top;
      right: $icon-details-divider__right;
      background: transparent;
    }

    &::after {
      right: $poi-list-item-end__divider-position;
    }
  }
}
