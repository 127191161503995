// sass-lint:disable variable-name-format
@import "./directives_variables";
@import "./global_functions";
@import "./global_mixins";

$scale__factor: 1;

$grid__unit: 4px;
$grid__row-bleeding-zone: 3 * $grid__unit;
$grid__row-count: 6;
$grid__column-count: 9;
$grid__column-count--interaction-screen: 4;
$grid__column-count--detail-screen: 2;
$grid__column-count--half-screen: 3;
$grid__column-width: 251px;
$display-width: 1920px;
$display-height: 720px;
$grid__row-height: ($display-height - $grid__row-bleeding-zone) / $grid__row-count; // 118px

$fading__height: 14 * $grid__unit;

$spacer__xs--hor: 1.5 * $grid__unit;
$spacer__xs: 2 * $grid__unit;
$spacer__s: 3 * $grid__unit;
$spacer__m: 4 * $grid__unit;
$spacer__ml: 6 * $grid__unit;
$spacer__l: 8 * $grid__unit;
$spacer__xl: 12 * $grid__unit;
$spacer__xxl: 16 * $grid__unit;

$size__s: $grid__unit;
$size__m: 15 * $grid__unit;
$size__l: 18 * $grid__unit;
$size__xl: 24 * $grid__unit;
$size__xxl: 42 * $grid__unit;

$sidebar__width: 39.75 * $grid__unit; // 159px
$screen__width--interaction-screen: $grid__column-count--interaction-screen * $grid__column-width +
  ($grid__column-count--interaction-screen - 1) * $spacer__m; // 1052px
$screen__width--detail-screen: $grid__column-count--detail-screen * $grid__column-width + $spacer__m; // 518px
$screen__width--half-and-half-screen: $grid__column-count--half-screen * $grid__column-width +
  ($grid__column-count--half-screen - 1) * $spacer__m; // 785px

// J1 Font sizes
$font__size--xxl: 30 * $grid__unit;
$font__size--xl: 16 * $grid__unit;
$font__size--l: 13 * $grid__unit;
$font__size--m: 11 * $grid__unit;
$font__size--normal: 9 * $grid__unit;
$font__size--s: 8 * $grid__unit;
$font__size--ms: 7 * $grid__unit;
$font__size--xs: 5.5 * $grid__unit;

// J1 Icon Sizes
$icon-size__xs: 9 * $grid__unit;
$icon-size__s: 12 * $grid__unit;
$icon-size__m: $size__m;
$icon-size__l: $size__l;
$icon-size__xl: $size__xl;
$icon-size__xxl: 30 * $grid__unit;

// J1 Line Heights
// TODO DA: Line Heights
$font__line-height--medium: calc(52em / 44);
$font__line-height--m: 13.5 * $grid__unit; // 54px
$font__line-height--normal: calc(43em / 36);
$font__line-height--s: 12 * $grid__unit; // 48px

$color__white--100: #fff;
$color__white--90: #e6e6e6;
$color__white--80: #ccc;
$color__white--70: #b3b3b3;
$color__white--60: #999;
$color__white--53: #888888;
$color__white--50: #808080;
$color__white--40: #666;
$color__white--30: #4d4d4d;
$color__white--28: #474747;
$color__white--20: #333;
$color__white--15: #252525;
$color__white--10: #1a1a1a;
$color__white--8: #141414;
$color__white--5: #0d0d0d;
$color__black-blue: #0a0a0a;
$color__phantom-blue: #00b0f4;
$color__phantom-blue--disabled: #004662;
$color__blue: #0f4999;
$color__focus-orange: #e2b236;
$color__focus-dark-orange: #ca712f;
$color__negative-red: #b33229;
$color__negative-dark-red: #820f0c;
$color__positive-green: #13d246;
$color__positive-dark-green: #0b7e2a;
$color__negative-red--disabled: #481410;
$color__border--enabled: rgba($color__white--80, 0.1);
$color__border--disabled: rgba($color__white--80, 0.05);
$color__border--pressed: rgba($color__phantom-blue, 0.2);

// J1 gradient colors
$color__midnight: #001e38;
$color__firefly: #06131b;
$color__daintree: #012a39;
$color__orient: #005b7c;
$color__lochmara: #008bc2;
$color__bronze: #472a09;
$color__dark-ebony: #321904;
$color__nero: #170a00;
$color__cherrywood: #661c17;

$color__primary--first: #000;
$color__primary--second: $color__white--100;
$color__secondary: #d8d8d8;

$color__highlight: $color__phantom-blue;
$color__focus: $color__focus-orange;
$color__focus--primary: $color__dark-ebony;
$color__focus--secondary: $color__nero;
$color__signal--positive: $color__positive-green;
$color__signal--warn: $color__focus-orange;
$color__signal--negative: $color__negative-red;

$color__icon-shadow--default: rgba($color__primary--first, 0.5);

$color__pressed: $color__white--100;
$color__normal: $color__white--80;
$color__disabled: $color__white--40;
$color__active: $color__highlight;

$color__list--item-icon-divider-background: $color__white--20;
$color__list--item-background: $color__black-blue;
$color__list--item-background-disabled: #141414;
$color__background: $color__primary--first;

$color__overlay-other--border: rgba(204, 204, 204, 0.1);
$color__overlay-other--divider: #494949;

$color__overlay-other-background-gradient--1: rgba(255, 255, 255, 0.1);
$color__overlay-other-background-gradient--2: rgba(255, 255, 255, 0.05);

$size__list-item-icon-divider-height: 9 * $grid__unit; // 36px
$size__divider-width: $grid__unit / 2; // 2px

$color__scrollbar--default: $color__white--20;
$color__scrollbar--thumb: $color__white--80;
$color__scrollbar--outline: rgba($color__white--100, 0.1);

$color__tooltip--background-gradient-color1: rgba($color__white--100, 0.1);
$color__tooltip--background-gradient-color2: rgba($color__white--100, 0);
$gradient__tooltip--background: radial-gradient(
  ellipse 40% 35% at 50% 0,
  $color__tooltip--background-gradient-color1 0%,
  $color__tooltip--background-gradient-color2 90%
);

$color__keyboard-background: $color__white--10;

$gradient__list-headline-disabled--background: $color__list--item-background-disabled 0%,
  $color__list--item-background 100%;

$gradient__deep-blue--ab: $color__midnight 0, $color__firefly 100%;
$gradient__deep-blue-transparent--ab: $color__midnight 0, transparentize($color__midnight, 1) 100%;
$gradient__dark-blue--ab: $color__lochmara 0, $color__orient 50%, $color__daintree 100%;
$gradient__default-blue--ab: $color__phantom-blue 0, $color__orient 100%;
$gradient__light-blue--ab: $color__phantom-blue 0, $color__lochmara 100%;
$gradient__blue-spotlight--aba: $color__lochmara 0, $color__phantom-blue 50%, $color__lochmara 100%;
$gradient__dark-blue-spotlight--aba: $color__orient 0, $color__phantom-blue 50%, $color__orient 100%;
$gradient__oceanblue--abc: $color__daintree 0, $color__orient 50%, $color__phantom-blue 100%;
$gradient__light-gray--ab: $color__white--40 0, $color__white--60 100%;
$gradient__medium-gray--ab: $color__white--20 0, $color__white--10 100%;
$gradient__dark-gray--ab: $color__white--30 0, $color__white--20 100%;
$gradient__superdark-gray--ab: $color__white--20 0, $color__white--10 100%;
$gradient__metro--ab: $color__white--70 0, $color__white--50 100%;
$gradient__metro-light: $color__white--100 0, $color__white--60 100%;
$gradient__sunflower-seeds--ab: $color__bronze 0, $color__dark-ebony 50%, $color__nero 100%;
$gradient__sunflower-list--abcba: $color__bronze 0, $color__dark-ebony 25%, $color__nero 50%, $color__dark-ebony 75%,
  $color__bronze 100%;
$gradient__bloody-mary--ab: $color__cherrywood 0, $color__negative-red 100%;

$font__color--primary: $color__white--80;
$font__color--secondary: $color__white--60;
$font__color--primary-active: $color__highlight;
$font__color--secondary-active: $color__white--80;
$font__color--primary-disabled: rgba(255, 255, 255, 0.4);
$font__color--secondary-disabled: #c2c2c2;

$border__color--primary: $color__white--30;
$border__color--secondary: $color__white--10;
$border__color--active: $color__border--enabled;
$border__icon--color--enabled: rgba($color__white--100, 0.4);
$border__icon--color--active: rgba($color__highlight, 0.4);
$border__icon--color--pressed: $color__white--100;
$border__icon--color--disabled: rgba($color__white--100, 0.1);
$border__radius--default: $grid__unit / 2;
$border__radius--tile: $border__radius--default;
$border__radius--big: 1.25 * $grid__unit;
$border__width--default: $grid__unit / 2;
$border__width--icon: 0.25 * $grid__unit; // 1px
$border__width--selected: $grid__unit * 0.75;
$border__width--focused: $grid__unit;
$color__icon--shadow--default: rgba(0, 0, 0, 0.5);

$line__offset--bottom: 0.25 * $grid__unit; // 1px

// Animation
$time__animation-duration--instant: 0s;
$time__animation-duration--veryfast: 0.1s;
$time__animation-duration--fast: 0.2s;
$time__animation-duration--medium: 0.3s;
$time__animation-duration--slow: 0.5s;
$time__animation-duration--slowest: 1s;

$time__animation-delay--short: 0.1s;
$time__animation-delay--medium: 0.3s;
$time__animation-delay--long: 0.5s;

$animation-timing-function--bezier-easein: cubic-bezier(0.25, 0.1, 0.25, 1);
$animation-timing-function--bezier-easeout: cubic-bezier(0.25, 0.6, 0, 1);

$shapes: circle, ellipse;
$sizes-h: 50, 100, 200;
$sizes-v: 50, 100, 200;
$positions-h: 0, 50, 100;
$positions-v: 0, 50, 100;
$disabled-opacity: 0.5;

$keyboard__width: $screen__width--interaction-screen;
$keyboard__border-radius: $border__radius--default;
$keyboard__background: #0f0f0f;

$quickfilter__icon-size: $icon-size__l;

// Overlays (Context back, Help text, Popup, Notification, Long press popup, Visual Feedback)
$color__overlay-background: rgba(10, 10, 10, 0.8);
$color__overlay-background-gradient--1: rgba(255, 255, 255, 0.1);
$color__overlay-background-gradient--2: rgba(255, 255, 255, 0.05);

$color__overlay-border--1: rgba(0, 0, 0, 0.9);
$color__overlay-border--2: rgba(204, 204, 204, 0.1);

$color__overlay-shadow--1: rgba(0, 0, 0, 0.41);
$color__overlay-shadow--2: rgba(0, 0, 0, 0.18);

$size__overlay-border--3: 0.75 * $grid__unit; // 3px
$size__overlay-border--2: 0.5 * $grid__unit; // 2px
$size__overlay-border--1: $size__overlay-border--2 - $grid__unit / $grid__unit; // 1px

$gradient__overlay-background-image: radial-gradient(
  ellipse 50% 100%,
  $color__overlay-background-gradient--1 0%,
  $color__overlay-background-gradient--2 100%
);

$overlay__border--2: inset 0 0 0 $size__overlay-border--2 $color__overlay-border--2;
$overlay__border--1: inset 0 0 0 $size__overlay-border--1 $color__overlay-border--1;
$overlay__border--3: 0 0 0 $size__overlay-border--1 $color__overlay-border--1;

$overlay__shadow: 0 15px 30px 0 $color__overlay-shadow--1, 0 0 1px 1px $color__overlay-shadow--2;
$overlay__border-radius: $border__radius--default;

$overlay__properties--popup: 1, 0.02, 0.8;
$overlay__properties--popup-volume: 1, 0.03, 0.8;

// Avatar & Cover
$cover-avatar__border-size: $grid__unit / 2;
$cover-avatar__border--1: linear-gradient(to bottom, $color__white--60 0%, $color__white--30 100%);
$cover-avatar__border--2: $cover-avatar__border-size solid $color__primary--first;
$cover-avatar__background: #0d0d0d;
$cover-avatar__radial-gradient: radial-gradient(ellipse 200% 100% at center top, $color__white--5 0%, transparent 100%);
$cover-avatar__gradient-overlay: linear-gradient(-180deg, $color__white--100 0%, $color__primary--first 100%);
$cover-avatar__gradient-overlay-opacity: 0.05;
$cover-avatar__shadow: 0 (2.5 * $grid__unit) (3.75 * $grid__unit) rgba(0, 0, 0, 0.5);
$cover-avatar-initials-icon__background-image: linear-gradient(-180deg, $color__white--60 0%, $color__white--20 100%);
$cover-avatar-initials-icon__border-color: rgba(255, 255, 255, 0.15);

// Context back
$overlay-other__border-radius: $cover-avatar__border-size solid $color__overlay-other--border;
$overlay-other__background-image: radial-gradient(
  ellipse 50% 100%,
  $color__overlay-other-background-gradient--1 0%,
  $color__overlay-other-background-gradient--2 100%
);

// Map Context colors
$map__background--control-bar: rgba(25, 25, 25, 0.9);
$map__border-color--control-bar: rgba(204, 204, 204, 0.1);

$map__background--tooltip: rgba(25, 25, 25, 0.9);
$map__border--tooltip: 1px solid rgba(0, 0, 0, 0.9);
$map__box-shadow--tooltip: inset 0 0 0 2px rgba(204, 204, 204, 0.1);
$map__box-shadow2--tooltip: 0 10px 15px 0 rgba($color__primary--first, 0.3);

// Map Overlays
$map-control-bar__width: $grid__row-height - $grid__unit / 2; // 116px

// Tiles & Buttons Disabled
$color__tiles-buttons-background-gradient--disabled-1: $color__black-blue;
$color__tiles-buttons-background-gradient--disabled-2: #141414;

$gradient__tiles-buttons-background-image--disabled: $color__tiles-buttons-background-gradient--disabled-1 0,
  $color__tiles-buttons-background-gradient--disabled-2 100%;

// FastScrollBar
$letter-slider__width: 6 * $grid__unit;

// Input text field
$color__input-text-field--selected: #087a98;

// Updates descriptions
$description__line-height: 12 * $grid__unit; // 48px
$description__max-height: $description__line-height * 20; // 960px
$description__margin: (4.75 * $grid__unit) (4 * $grid__unit); // 19px 16px

// Blocking overlay
$blocking-overlay--width: 228 * $grid__unit; // 912px
$blocking-overlay--left: 200 * $grid__unit; // 800px

// custom font-family names
$font--plain-latin: "Porsche Plain Latin";
$font--plain-je: "Porsche Plain JE";
$font--plain-k: "Porsche Plain K";
$font--plain-hk: "Porsche Plain HK";
$font--plain-tc: "Porsche Plain TC";
$font--plain: $font--plain-latin, $font--plain-je, $font--plain-k, $font--plain-hk, $font--plain-tc;

$font--semibold-latin: "Porsche SemiBold Latin";
$font--semibold-je: "Porsche SemiBold JE";
$font--semibold-k: "Porsche SemiBold K";
$font--semibold-hk: "Porsche SemiBold HK";
$font--semibold-tc: "Porsche SemiBold TC";
$font--semibold: $font--semibold-latin, $font--semibold-je, $font--semibold-k, $font--semibold-hk, $font--semibold-tc;

$font--plain-numeric: "Porsche Plain Numeric";

$font--semibold-numeric: "Porsche SemiBold Numeric";

$font--plain-exp: "Porsche Plain Expanded";

$font--bold-exp: "Porsche Bold Expanded";

$font--monda: "Monda Regular";

$font--icons: "pag3-icon-font";
