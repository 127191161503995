@import "../../../pagGlobalstyles/sass/global_variables";
@import "../../../pagGlobalstyles/sass/global_mixins";
@import "../../../pagGlobalstyles/sass/fonts";

$background-color: $color__background;

$info__padding-top--bluetooth-device-list: 36.25 * $grid__unit; // 145px

$icon__size: $icon-size__xl;
$icon__margin-bottom: $spacer__xs;
$icon__margin-bottom--favorites-edit-mode: $spacer__xl;

$message__font-size: $font__size--normal;
$message__color: $font__color--primary;
$message__max-width: 400.5 * $grid__unit; // 1602px
$message__line-height: 13.5 * $grid__unit; // 54px
$message__max-height: 4 * $message__line-height; // 216px
$message__max-height--ww: 5 * $font__line-height--s; // 240px

$buttons__padding: $spacer__m $spacer__m 8.25 * $grid__unit; // 16px 16px 33px

$background-highlight__height: 125 * $grid__unit; // 500px
$background-highlight__width: 300 * $grid__unit; // 1200px
$background-highlight__opacity: 1;
$background-highlight__position: -43 * $grid__unit + ($grid__unit - 1);
$background-highlight__gradient-color--1: rgba(255, 255, 255, 0.7);
$background-highlight__gradient-color--2: rgba(255, 255, 255, 0.05);
$background-highlight__gradient-color--3: transparent;
$background-highlight__background-image: radial-gradient(
  $background-highlight__gradient-color--1 0%,
  $background-highlight__gradient-color--2 45%,
  $background-highlight__gradient-color--3 100%
);
$background-highlight__position--transform-right: translate(0, -50%);
$background-highlight__position--transform-left: translate(0, -50%);
$background-highlight__position--transform-centered: translate(-50%, -50%);

$shadow__blur-radius: 60 * $grid__unit; // 240px
$shadow__spread-radius: 30 * $grid__unit; // 120px

@mixin flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.info-message-wrapper {
  @include flex-center;
  height: 100%;
  width: 100%;
  #{--boxed}: true;
}

.bg-dotgrid {
  position: absolute;
  width: 100%;
  height: 100%;

  &__mask-highlight {
    background-repeat: no-repeat;
    box-shadow: inset 0 0 $shadow__blur-radius $shadow__spread-radius $color__primary--first;

    #{$selector-steering-wheel-left} & {
      background-image: url("/assets/graphics-master/general/j1_dot_grid_without_glow_bg_lhw.png");
    }

    #{$selector-steering-wheel-right} & {
      background-image: url("/assets/graphics-master/general/j1_dot_grid_without_glow_bg_rhw.png");
    }

    // Highlight
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      height: $background-highlight__height;
      width: $background-highlight__width;
      border-radius: 50%;
      opacity: $background-highlight__opacity;
      background-image: $background-highlight__background-image;
      mix-blend-mode: overlay;

      #{$selector-steering-wheel-left} & {
        right: $background-highlight__position;
        transform: $background-highlight__position--transform-right;
      }

      #{$selector-steering-wheel-right} & {
        left: $background-highlight__position;
        transform: $background-highlight__position--transform-left;
      }
    }
  }

  &.bg-dotgrid__centered {
    #{$selector-steering-wheel-left} &,
    #{$selector-steering-wheel-right} & {
      background-image: url("/assets/graphics-master/general/j1_dot_grid_bg.png");

      &::before {
        left: 50%;
        right: unset;
        transform: $background-highlight__position--transform-centered;
      }
    }
  }

  &__wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.info {
  @include flex-center;
  flex-grow: 1;
  width: 100%;

  &__icon {
    height: $icon__size;
    width: $icon__size;
    margin-bottom: $icon__margin-bottom;
    font-size: $icon__size;
  }

  &__message {
    @include font-pag-plain;
    @include text-tool-label($message__max-height, $message__max-width);
    display: flex;
    font-size: $message__font-size;
    text-align: center;
    color: $message__color;

    // #{$selector-latin-layout} & {
    @include multiline-fading(left, $message__line-height, 1, 2);
    // }

    #{$selector-arabic-layout} & {
      @include multiline-fading(right, $message__line-height, 1, 2);
    }
  }
}

.buttons {
  display: flex;
  padding: $buttons__padding;
  margin-top: 50px; // added
}

.info-message-wrapper.si-player--tile {
  .buttons {
    flex-direction: column;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0;
  }
}

.info-message-wrapper .favorites-edit-mode--icon {
  .info__icon {
    margin-bottom: $icon__margin-bottom--favorites-edit-mode;
  }
}

.info-message-wrapper.accesspoint-list__details,
.info-message-wrapper.bluetooth-device-list__details {
  .info {
    padding-top: $info__padding-top--bluetooth-device-list;
  }
}

.info-message-wrapper.ww {
  .info {
    flex-grow: 0;
  }

  .info__message {
    max-height: $message__max-height--ww;
  }

  .buttons {
    padding: 0;
  }
}
